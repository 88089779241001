import dayjs from "dayjs";

const nth = function (d) {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return "th";
  switch (last % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDate = (date) => {
  const formattedDate = new Date(Date.parse(date));

  const day = formattedDate.getDate().toString().padStart(2, "0");
  const monthIndex = formattedDate.getMonth();
  const year = formattedDate.getFullYear().toString();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[monthIndex];

  return `${day} ${month}, ${year}`;
};

export const convertUnixTimeStampWithMonthName = (expiresAt) => {
  const unixTimestamp = expiresAt;
  const milliseconds = unixTimestamp * 1000; // 1575909015000
  const dateObject = new Date(milliseconds);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][dateObject.getMonth()];

  const str =
    month +
    " " +
    dateObject.getDate() +
    nth(dateObject.getDate()) +
    "," +
    dateObject.getFullYear();

  return str;
};

export const convertUnixTimeStamp = (data) => {
  const unixTimestamp = data;
  const milliseconds = unixTimestamp * 1000; // 1575909015000

  const dateObject = new Date(milliseconds);

  // 12/9/2019, 10:30:15 AM CST

  const converted = dateObject.toLocaleString(); // 12/9/2019, 10:30:15 AM CST

  // console.log("converted",converted);
  return converted;
};

export const addMissingDates = (startDate, endDate, data) => {
  const startDatedayjs = dayjs(startDate);
  const endDatedayjs = dayjs(endDate);

  const totalDays = endDatedayjs.diff(startDatedayjs, "day");

  // totalDays => 2

  for (let i = 0; i <= totalDays; i++) {
    const currentDate = startDatedayjs.add(i, "day");
    const dateExists = data.some((item) => {
      return dayjs(item["DumpTableMain.eventTime"]).isSame(currentDate, "day");
    });
    if (!dateExists) {
      // missingDates.push({ "DumpTableMain.eventTime": currentDate.format("YYYY-MM-DD"), "DumpTableMain.count": 0 });
      data.push({
        "DumpTableMain.eventTime": currentDate.format("YYYY-MM-DD"),
        "DumpTableMain.count": 0,
      });
    }
  }

  const sortedData = data.sort(function (a, b) {
    return (
      new Date(a["DumpTableMain.eventTime"]) -
      new Date(b["DumpTableMain.eventTime"])
    );
  });

  return sortedData;
};
