import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, secure_instance } from "../../axios/axios-config";
import authSlice, { handleResetStripeForm } from "./authSlice";

const initialState = {
  TransactionHistory: [],
  stripeCardsList: [],
  defaultPaymentMethod: "",
  customerInvoices: [],
  stripePricings: [],
  subscription: {
    stripePriceId: null,
    name: null,
    productId: null,
    expiresAt: null,
  },
  currentStripePriceId: null,
  tableData: {
    rowsPerPage: 5,
    page: 1,
  },
  paymentPlans: {
    isMonthly: true,
    isYearly: false,
    plan: "Starter",
  },
  couponCode: "",
  couponResult: {
    isCouponCodeApplied: false,
    couponErrorText: "",
    amountOff: null,
    percentOff: null,
  },
  upgradePlanError: {
    isError: false,
    errorMessage: "",
  },
  IsAddNewCard: false,
  isSideMenu: false,
  isConfirmPlanSideMenu: false,
  loading: false,
  isPaymentMethodExpired: false,
  isPaymentMethodExpiredSideMenu: false,
  isChangePlanView: false,
  paymentMethodLoader: false,
};

// export const createStripePaymentMethod = createAsyncThunk(
//   "paymentMethods/create",
//   async (
//     { stripe, elements, CardNumberElement, data, IsAddNewCard },
//     { rejectWithValue },
//   ) => {
//     try {
//       console.log("Creating payment method:", data);

//       if (!stripe || !elements) {
//         throw new Error("Stripe or elements not initialized correctly.");
//       }

//       const cardElement = elements.getElement(CardNumberElement);
//       if (!cardElement) {
//         throw new Error("CardNumberElement is not correctly set.");
//       }

//       const paymentMethod = await stripe.createPaymentMethod({
//         type: "card",
//         card: cardElement,
//         billing_details: {
//           name: data.name,
//           address: {
//             country: data.country.value,
//             postal_code: data.postalCode,
//             line1: data.address,
//           },
//         },
//       });

//       console.log("Payment method created:", paymentMethod.paymentMethod.id);

//       await secure_instance.request({
//         url: "v1/stripe_customers/attach_payment_method/",
//         method: "POST",
//         data: {
//           payment_method_id: paymentMethod.paymentMethod.id,
//           make_default_payment_method: false,
//         },
//       });

//       console.log("Payment method attached successfully.");

//       return { IsAddNewCard };
//     } catch (error) {
//       console.error("Error creating payment method:", error);
//       return rejectWithValue(
//         error.response ? error.response.data : "Unknown error",
//       );
//     }
//   },
// );
export const listStripePaymentMethods = createAsyncThunk(
  "paymentMethods/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: "v1/stripe_customers/list_payment_methods/",
        method: "GET",
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createStripePaymentMethod = createAsyncThunk(
  "paymentMethods/create",
  async (
    { stripe, elements, CardNumberElement, data, IsAddNewCard },
    { dispatch, rejectWithValue },
  ) => {
    try {
      console.log("Creating payment method:", data);

      if (!stripe || !elements) {
        throw new Error("Stripe or elements not initialized correctly.");
      }

      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        throw new Error("CardNumberElement is not correctly set.");
      }

      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: data.name,
          address: {
            country: data.country.value,
            postal_code: data.postalCode,
            line1: data.address,
          },
        },
      });

      console.log("Payment method created:", paymentMethod.paymentMethod.id);

      await secure_instance.request({
        url: "v1/stripe_customers/attach_payment_method/",
        method: "POST",
        data: {
          payment_method_id: paymentMethod.paymentMethod.id,
          make_default_payment_method: false,
        },
      });

      console.log("Payment method attached successfully.");

      // Now dispatch the action to list payment methods
      await dispatch(listStripePaymentMethods());

      return { IsAddNewCard };
    } catch (error) {
      console.error("Error creating payment method:", error);
      return rejectWithValue(
        error.response ? error.response.data : "Unknown error",
      );
    }
  },
);

export const getSubscriptions = createAsyncThunk(
  "subscriptions/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: "v1/subscriptions/?expand=[tenant, stripe_pricing, stripe_product]",
        method: "GET",
      });
      return response.data[0]; // Assuming the response wraps the data in an array
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const listStripeCustomerInvoices = createAsyncThunk(
  "invoices/listStripeCustomer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: "v1/stripe_customers/customer_invoices",
        method: "GET",
      });
      return response.data.data; // Assuming the response structure includes a data object
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const getStripePricings = createAsyncThunk(
  "pricing/getStripePricings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await instance.request({
        url: "v1/stripe_products/",
        method: "GET",
      });
      return response.data; // Assuming the response structure directly contains the data needed
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const makeDefaultPaymentMethod = createAsyncThunk(
  "paymentMethods/makeDefault",
  async (row, { rejectWithValue }) => {
    try {
      await secure_instance.request({
        url: "v1/stripe_customers/default_payment_method",
        method: "POST",
        data: {
          payment_method_id: row.id,
        },
      });
      return row; // Return the row as the new default payment method
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const detachPaymentMethodInStore = createAsyncThunk(
  "paymentMethods/detach",
  async (row, { rejectWithValue }) => {
    try {
      await secure_instance.request({
        url: "v1/stripe_customers/detach_payment_method",
        method: "POST",
        data: {
          payment_method_id: row?.id,
        },
      });
      return row; // Return the row to identify which payment method was detached
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const handleCancelSubscriptionInStore = createAsyncThunk(
  "subscriptions/cancel",
  async (id, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: `v1/subscriptions/${id}`,
        method: "DELETE",
      });
      return response.data; // Depending on your backend, you might want to return some value or just confirm success
    } catch (error) {
      console.error("error", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const handleUpgradePlan = createAsyncThunk(
  "plans/upgrade",
  async ({ stripePriceId, history }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(handleUpgradePlanStart());

      const request = await secure_instance.request({
        url: "v1/subscriptions/?expand=[tenant, stripe_pricing, stripe_product]",
        method: "GET",
      });

      await secure_instance.request({
        url: `v1/subscriptions/${request.data[0].id}`,
        method: "PATCH",
        data: {
          stripe_pricing_id: stripePriceId,
        },
      });

      dispatch(handleUpgradePlanEnd());
      dispatch(toggleConfirmPlanSideMenu());
      dispatch(getSubscriptions());
      dispatch(setIsChangePlanView(false));
      // history.goBack();
      dispatch(listStripeCustomerInvoices());
    } catch (e) {
      dispatch(
        upgradePlanFailed({
          isError: true,
          errorMessage: e.toString(),
        }),
      );
      dispatch(handleUpgradePlanEnd());
      return rejectWithValue(e.response.data);
    }
  },
);

export const checkCouponDetails = createAsyncThunk(
  "coupon/checkDetails",
  async (couponCode, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: `v1/stripe_customers/coupon_details/${couponCode}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    createSubscriptionStart(state) {
      state.loading = true;
    },

    createSubscriptionFailed(state) {
      state.loading = false;
    },
    createSubscriptionEnd(state) {
      state.loading = false;
      // state.isSubscribed = true;
    },
    IsAddNewCardInStore(state) {
      state.IsAddNewCard = !state.IsAddNewCard; // Toggle the state
    },
    setDefaultPaymentMethod(state, action) {
      state.defaultPaymentMethod = action.payload;
    },
    handleSideMenu(state) {
      state.isSideMenu = !state.isSideMenu;
    },
    toggleConfirmPlanSideMenu(state) {
      state.isConfirmPlanSideMenu = !state.isConfirmPlanSideMenu;
    },
    setMonthly(state, action) {
      console.log("action.payload month", action.payload);
      state.paymentPlans.isMonthly = action.payload;
    },
    setYearly(state, action) {
      console.log("action.payload", action.payload);
      state.paymentPlans.isYearly = action.payload;
    },
    handleChangePlan(state, action) {
      state.paymentPlans.plan = action.payload;
    },
    getCurrentPlan(state, action) {
      state.paymentPlans.isMonthly = action.payload.isMonthly;
      state.paymentPlans.isYearly = action.payload.isYearly;
      state.paymentPlans.plan = action.payload.name;
    },
    handleUpgradePlanStart(state) {
      state.loading = true;
    },
    handleUpgradePlanEnd(state) {
      state.loading = false;
    },
    setIsChangePlanView(state, action) {
      state.isChangePlanView = action.payload;
    },
    upgradePlanFailed(state, action) {
      state.upgradePlanError = action.payload;
    },
    handleCouponCodeChangeInStore(state, action) {
      state.couponCode = action.payload.value;
    },
    upgradePlanErrorStateRefresh(state) {
      state.upgradePlanError = {
        isError: false,
        errorMessage: null,
      };
    },
    removeCoupon(state) {
      state.couponResult = initialState.couponResult;
      state.couponCode = initialState.couponCode;
    },
    setIsPaymentMethodExpired(state) {
      state.isPaymentMethodExpired = !state.isPaymentMethodExpired;
    },
    togglePaymentMethodExpiredSideMenu(state) {
      state.isPaymentMethodExpiredSideMenu =
        !state.isPaymentMethodExpiredSideMenu;
    },
    setStripePricingIdInStore(state, action) {
      state.currentStripePriceId = action.payload;
    },
    setTablePageInBillingStore(state, action) {
      state.tableData.page = action.payload;
    },
    //   handleResetStripeForm(state) {
    //   state.stripePaymentForm = { ...initialState.stripePaymentForm };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStripePaymentMethod.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStripePaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.IsAddNewCard) {
          state.isSideMenu = !state.isSideMenu;
        }
        state.IsAddNewCard = false;
        // authSlice.
        // handleResetStripeForm();
        // dispatch(handleResetStripeForm());
        // listStripePaymentMethods();
      })
      .addCase(createStripePaymentMethod.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to create payment method";
      })
      .addCase(listStripePaymentMethods.pending, (state) => {
        state.paymentMethodLoader = true;
      })
      .addCase(listStripePaymentMethods.fulfilled, (state, action) => {
        state.stripeCardsList = action.payload;
        state.paymentMethodLoader = false;
      })
      .addCase(listStripePaymentMethods.rejected, (state, action) => {
        state.paymentMethodLoader = false;
        state.error = action.payload || "Failed to list payment methods";
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscription = {
          stripePriceId: action.payload.stripe_pricing_id,
          name: action.payload.stripe_product.name,
          productId: action.payload.stripe_product.id,
          expiresAt: action.payload.expires_at,
          interval: action.payload.stripe_pricing.interval,
        };
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch subscriptions";
      })
      .addCase(listStripeCustomerInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(listStripeCustomerInvoices.fulfilled, (state, action) => {
        state.customerInvoices = action.payload;
        state.loading = false;
      })
      .addCase(listStripeCustomerInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch customer invoices";
      })
      .addCase(getStripePricings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStripePricings.fulfilled, (state, action) => {
        state.stripePricings = action.payload;
        state.loading = false;
      })
      .addCase(getStripePricings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch Stripe pricings";
      })
      .addCase(makeDefaultPaymentMethod.pending, (state) => {
        state.loading = true;
      })
      .addCase(makeDefaultPaymentMethod.fulfilled, (state, action) => {
        state.defaultPaymentMethod = action.payload;
        state.loading = false;
      })
      .addCase(makeDefaultPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to set default payment method";
      })
      .addCase(detachPaymentMethodInStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(detachPaymentMethodInStore.fulfilled, (state, action) => {
        state.loading = false;
        // Filter out the detached payment method
        state.stripeCardsList = state.stripeCardsList.filter(
          (card) => card.id !== action.payload.id,
        );
      })
      .addCase(detachPaymentMethodInStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to detach payment method";
      })
      // ------------------------  THIS ADD CASE IS NOT YET FINALIZED ------------------------
      .addCase(handleCancelSubscriptionInStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleCancelSubscriptionInStore.fulfilled, (state, action) => {
        // Optionally remove the subscription from the list if you store such data
        // state.subscriptions = state.subscriptions.filter(sub => sub.id !== action.meta.arg);
        state.loading = false;
      })
      .addCase(handleCancelSubscriptionInStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to cancel subscription";
      })
      .addCase(checkCouponDetails.fulfilled, (state, action) => {
        state.couponResult = {
          isCouponCodeApplied: action.payload.valid ? true : false,
          couponErrorText: action.payload.valid ? "" : "Coupon Code not valid",
          amountOff: action.payload.amount_off,
          percentOff: action.payload.percent_off,
        };
      })
      .addCase(checkCouponDetails.rejected, (state, action) => {
        state.couponResult.couponErrorText =
          action.payload || "Error fetching coupon details";
      });
  },
});

export const {
  createSubscriptionStart,
  createSubscriptionFailed,
  createSubscriptionEnd,
  IsAddNewCardInStore,
  setDefaultPaymentMethod,
  handleSideMenu,
  toggleConfirmPlanSideMenu,
  setMonthly,
  setYearly,
  handleChangePlan,
  getCurrentPlan,
  handleUpgradePlanStart,
  handleUpgradePlanEnd,
  setIsChangePlanView,
  upgradePlanFailed,
  handleCouponCodeChangeInStore,
  upgradePlanErrorStateRefresh,
  removeCoupon,
  setIsPaymentMethodExpired,
  togglePaymentMethodExpiredSideMenu,
  setStripePricingIdInStore,
  setTablePageInBillingStore,
} = billingSlice.actions;

export default billingSlice.reducer;
