import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import { Drawer } from "@mui/material";
import Field from "../sub-components/Field";
import Button from "../sub-components/Button";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import { secure_instance } from "../../axios/axios-config";
import { MenuItem } from "@mui/material";
import { ReactComponent as DotsThreeHorizontal } from "../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../assets/icons/PencilSimple.svg";
import { ReactComponent as Close } from "../../../src/assets/icons/X.svg";
import { styled } from "@mui/material/styles";
import { Table } from "../Tables/Table";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as MinusCheckbox } from "../../assets/icons/MinusCheckbox.svg";
import { ReactComponent as AddToTeamPopUp } from "../../assets/icons/AddToTeamPopUp.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import { StyledMenu } from "../Menus/Menu";
import Tabv2 from "../sub-components/Tabv2";
import AnalyticsSideBar from "./Tabs/Analytics";
import SideBarSurveys from "./Tabs/Surveys";
import SidebarObjectives from "./Tabs/Objectives";
import {
  getGroupsData,
  handleCreateTeam,
  handleCreateTeamButtonClick,
  handleUpdateTeamData,
  setSideMenuTableRowsPerPage,
  setTeamsSideMenuTablePage,
  toggleAddMenu,
  toggleSideMenuTableLoading,
} from "../../redux/slices/teamsSlice";
import { handleConnectionStatus } from "../../redux/slices/identityProviderSlice";
import { emptyRows } from "../../utilities/Utils";
import CustomSelect from "../sub-components/Select";
import CustomBox from "../Modals/CustomBox";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "none",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
  },
}));

const headCellsGroupsEditView = [
  {
    key: "name",
    name: "Groups",
    minWidth: 300,
    padding: "0 0 0 32px",
  },
];

// FOR MANUAL TEAM SIDE MENU
const usersColumns = [
  {
    key: "name",
    name: "Name",
    accessor: (row) => `${row.first_name}  ${row.last_name}`,
    // minWidth: 300,
  },
  {
    key: "emai",
    name: "Email",
    accessor: (row) =>
      row.identifiers?.map(
        (identifier) => identifier.is_primary && identifier.email,
      ),
    // minWidth: 200,
  },
  {
    key: "_",
    name: "Job Title",
    // minWidth: 200,
  },
];

export default function TeamsMenu(props) {
  const { currentRowToEdit, deleteTeam, setCurrentRowToEdit } = props;

  const {
    teamsData,
    groupsData,
    showAddMenu,
    teamDeletedSuccess,
    isTeamCreatedButtonClick,
    loading,
  } = useSelector((state) => state.teamsReducer);

  const { user } = useSelector((state) => state.authReducer);
  // const { tableLoading } = useSelector((state) => state.teamsReducer.tableData);
  // const { toggleSideMenuTableLoading } = useSelector((state) => state.teamsReducer.tableData);

  const { providersName } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );

  const { page, rowsPerPage, sideMenuTableLoading } = useSelector(
    (state) => state.teamsReducer.sideMenu.tableData,
  );

  const tabsInitialState = [
    { id: 1, title: "Team Details" },
    { id: 2, title: "Users" },
    { id: 3, title: "Analytics" },
    { id: 4, title: "Surveys" },
    { id: 5, title: "Objectives" },
    // Add more tabs as needed
  ];

  const [tabs, setTabs] = useState(tabsInitialState);
  const [selectedTab, setSelectedTab] = useState({ id: 1 });
  const [teamName, setTeamName] = useState("");
  const [name, setName] = useState(undefined);
  const [teamDescription, setTeamDescription] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [actionMenu, setActionMenu] = useState(false);
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown
  const [addMembers, setAddMembers] = useState([]); // users which have been added from dropdown
  const [memberSelected, setMemberSelected] = useState([]); // users which are inside addMembers and current selected user inside dropdown
  const [addedUsersSelected, setAddedUsersSelected] = useState([]); // users which have been added from dropdown and selected
  const [addedUsers, setAddedUsers] = useState([]); // users which have been added
  const [usersToRemoveSelected, setUsersToRemoveSelected] = useState([]); // users which have been added, and selected to remove
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [inactiveGroups, setInactiveGroups] = useState(false);
  const [isTeamName, setIsTeamName] = useState(false);
  const [isNameActive, setIsNameActive] = useState(false);
  const [isDescriptionActive, setIsDescriptionActive] = useState(false);
  const [imageUrlToUpload, setImageUrlToUpload] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [show, setShow] = useState(false);
  const [teamType, setTeamtype] = useState("");
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [allUsersData, setAllUsersData] = useState([]);
  const [teamLead, setTeamLead] = useState(null);
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(null);
  const [isEditableState, setIsEditableState] = useState(false);
  const [addedUsersFromTeam, setAddedUsersFromTeam] = useState([]);
  const isButtonActionMenu = Boolean(anchorEl);

  const [usersToAddConfirmed, setUsersToAddConfirmed] = useState([]);
  const [usersToDeleteConfirmed, setUsersToDeleteConfirmed] = useState([]);

  const groupId = [];
  const dispatch = useDispatch();

  const groupsColumns = [
    {
      key: "name",
      name: "Groups",
      // minWidth: 200,
      // padding: "0 0 0 16px",
    },
    {
      key: "action",
      name: "Action",
      align: "right",
      padding: "0 32px 0 0",
      accessor: (row) => (
        <div>
          {teamsData.map((team) => {
            groupId.push(team?.team_group[0].group_id);
          })}
          {currentRow.id == row.id ? (
            <MinusCheckbox
              onClick={handleOpenModal}
              style={{ borderRadius: "2px", cursor: "pointer" }}
            />
          ) : (
            <CustomTooltip
              title={<AddToTeamPopUp />}
              placement="top"
              disableInteractive
            >
              <Plus
                stroke={inactiveGroups ? "#a1a1a1" : "#343A40"}
                style={{
                  cursor: "pointer",
                  pointerEvents: inactiveGroups && "none",
                }}
                onClick={() => handleCurrentRow(row)}
              />
            </CustomTooltip>
          )}
        </div>
      ),
      // minWidth: 200,
    },
  ];

  const handlePopUpMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleActionMenu = (type) => {
    if (type === "open") {
      setActionMenu(true);
      return;
    }
    setActionMenu(!actionMenu);
  };

  const handleThreeDotsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  const handleTeamType = (option) => {
    if (option === "auto") {
      dispatch(getGroupsData());
    }
    getUsersList();
    setTeamtype(option);
  };

  const handleTeamName = (e) => {
    setIsNameActive(true);
    setIsTeamName(false);
    setTeamName(e.target.value);
    if (e.target.value === "" && !isRowClicked) {
      setIsNameActive(false);
      setName("empty");
      return;
    }
    setName(e.target.value);
  };

  const handleTeamDescription = (e) => {
    setIsDescriptionActive(true);
    setTeamDescription(e.target.value);
  };

  const handleCurrentRow = (row) => {
    setInactiveGroups(true);
    setIsNameActive(true);
    if (Object.keys(currentRow).length == 0) {
      setCurrentRow(row);
      if (name === "empty" || name === undefined) {
        setIsTeamName(true);
      }
    } else {
      alert("Only one group be added at one time");
    }
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const refreshStates = () => {
    setTeamName("");
    setName(undefined);
    setUsersData("");
    setIsTeamName(false);
    setInactiveGroups(false);
    setIsNameActive(false);
    setIsDescriptionActive(false);
    setTeamDescription("");
    setTeamtype("");
    setCurrentRow({});
    setMemberSelected([]);
    setAddMembers([]);
    setImageUrlToUpload(null);
    setCurrentRowToEdit({});
    setIsRowClicked(false);
    setIsConfirmationModal(false);
    setTab2(false);
    setTab1(true);
    setAddedUsers([]);
    setAddedUsersSelected([]);
    setUsersToRemoveSelected([]);
    setIsEditableState(false);
    setSideMenuTablePage(1);
    setSelectedTab({ id: 1 });
    setTeamLead(null);
  };

  const handleNewTeamData = (e) => {
    e.preventDefault();
    if (teamType === "auto" && Object.keys(currentRow).length === 0) {
      alert("Select at least one group for team");
      return;
    } else if (teamType === "auto" && Object.keys(currentRow).length > 0) {
      const new_team_data = [
        {
          tenant_id: user.tenantId,
          ...(imageUrlToUpload !== null && { image_url: imageUrlToUpload }),
          name: isTeamName ? currentRow.name : teamName,
          description: teamDescription,
          group_id: currentRow.id,
          manager_id: teamLead ?? null,
        },
      ];

      const map = { teams: new_team_data };
      dispatch(handleCreateTeam(map, teamType));
    } else if (teamType == "manual" && addedUsers.length > 0) {
      const new_team_data = {
        group: {
          tenant_id: user.tenantId,
          name: name,
          identifier:
            // providersName === "GSuite"
            //   ? "google"
            //   : providersName === "Microsoft Azure" && "microsoft"

            "google",
          member_count: addedUsers.length.toString(),
          sync_type: teamType,
          group_members: addedUsers.map((ids) => ({ user_id: ids.user_id })),
          team: [
            {
              name: name,
              ...(imageUrlToUpload !== null && { image_url: imageUrlToUpload }),
              description: teamDescription,
              tenant_id: user.tenantId,
              manager_id: teamLead ?? null,
            },
          ],
        },
      };

      dispatch(handleCreateTeam(new_team_data, teamType));
    }
  };

  const handleTeamEdit = () => {
    if (teamType == "auto") {
      const new_team_data = {
        tenant_id: user.tenantId,
        name: isTeamName ? currentRow.name : teamName,
        manager_id: teamLead ?? null,
        group_id: currentRowToEdit.group_id,
        ...(imageUrlToUpload !== null && { image_url: imageUrlToUpload }),
        description:
          isRowClicked && !isDescriptionActive
            ? currentRowToEdit.description ?? ""
            : teamDescription,
      };
      const map = { teams: [new_team_data] };
      dispatch(handleUpdateTeamData(map, currentRowToEdit.id, teamType));
    }

    if (teamType == "manual") {
      const new_team_data = {
        id: currentRowToEdit.group_id,
        tenant_id: user.tenantId,
        name: teamName,

        identifier:
          providersName === "GSuite"
            ? "google"
            : providersName === "Microsoft Azure" && "microsoft",
        member_count: addedUsers.length.toString(),
        sync_type: teamType,
        group_members: addedUsers.map((ids) => {
          return {
            user_id: ids.user_id,
            group_id: currentRowToEdit.group_id,
            id: ids.id,
          };
        }),
        team: [
          {
            name: teamName,
            // description: teamDescription,
            ...(teamDescription !== null && { description: teamDescription }),
            ...(imageUrlToUpload !== null && { image_url: imageUrlToUpload }),
            tenant_id: user.tenantId,
            group_id: currentRowToEdit.group_id,
            id: currentRowToEdit.id,
            manager_id: teamLead ?? null,
          },
        ],
      };

      const map = { group: [new_team_data] };
      dispatch(handleUpdateTeamData(map, currentRowToEdit.group_id, teamType));
    }
  };

  const getUsersList = async () => {
    if (usersData.length === 0 || isRowClicked) {
      try {
        // dispatch(teamsActions.toggleTableLoading());
        dispatch(toggleSideMenuTableLoading());
        const request = await secure_instance.request({
          url: `v1/user_profile/?type=all`,
          method: "GET",
        });
        if (isRowClicked) {
          const newArray = request.data.filter(
            (array22) =>
              !addedUsers.some(
                (array11) => array11.user_id === array22.user_id,
              ),
          );
          setUsersData(newArray);
        } else {
          setUsersData(request.data);
        }
        setAllUsersData(request.data);
        dispatch(toggleSideMenuTableLoading());

        // dispatch(teamsActions.toggleTableLoading());
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClickCheckbox = (user) => {
    const selectedIndex = usersToRemoveSelected.findIndex(
      (x) => x.user_id === user.user_id,
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(usersToRemoveSelected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(usersToRemoveSelected.slice(1));
    } else if (selectedIndex === usersToRemoveSelected.length - 1) {
      newSelected = newSelected.concat(usersToRemoveSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        usersToRemoveSelected.slice(0, selectedIndex),
        usersToRemoveSelected.slice(selectedIndex + 1),
      );
    }

    setUsersToRemoveSelected(newSelected);
  };

  const handleClickAddedUsers = (user) => {
    const selectedIndex = addedUsersSelected.findIndex(
      (x) => x.user_id === user.user_id,
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(addedUsersSelected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(addedUsersSelected.slice(1));
    } else if (selectedIndex === addedUsersSelected.length - 1) {
      newSelected = newSelected.concat(addedUsersSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        addedUsersSelected.slice(0, selectedIndex),
        addedUsersSelected.slice(selectedIndex + 1),
      );
    }

    setAddedUsersSelected(newSelected);
  };

  const handleRemoveFromTeam = () => {
    handleActionMenuClose();
    handleOpenModal();
  };

  const handleToolbarAction = () => {
    const filteredUsersData = usersData.filter(
      (data) => !addedUsersSelected.includes(data),
    );
    setUsersData(filteredUsersData);
    setAddedUsers([...addedUsers, ...addedUsersSelected]);
    setAddedUsersSelected([]);
  };

  const handleTeamTypeMenu = () => {
    setShow(!show);
  };

  const setSideMenuTablePage = (data) => {
    // console.log("datadatadatadatadatadatadata", data);
    dispatch(setTeamsSideMenuTablePage(data));
  };

  const setSideMenuRowsPerPage = (data) => {
    dispatch(setSideMenuTableRowsPerPage(data));
  };

  const handleSideMenuClose = () => {
    dispatch(toggleAddMenu());
    if (isTeamCreatedButtonClick) {
      dispatch(handleCreateTeamButtonClick());
    }
  };

  const getModalGroup = () => (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox>
        <span className="head6 primary-color">Remove from team</span>

        <span className="s1 grey8" style={{ paddingTop: "3px" }}>
          Are you sure you want to remove group
        </span>

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            borderTop: "1px solid #E9ECEF",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "18px",
              cursor: "pointer",
            }}
          >
            <div className="s1 grey8" onClick={handleCloseModal}>
              Cancel
            </div>
            <div
              className="s1 primary-color"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentRow({});
                setInactiveGroups(false);
                handleCloseModal();
              }}
            >
              Yes, remove
            </div>
          </div>
        </div>
      </CustomBox>
    </Modal>
  );

  const getTableData = () => {
    if (teamType === "auto") {
      if (isRowClicked) {
        return [currentRowToEdit?.team_group[0]];
      }
      return groupsData;
    }
    if (teamType === "manual") {
      return usersData;
    }
  };

  const getTableCells = () => {
    if (teamType === "auto") {
      if (isRowClicked) {
        return headCellsGroupsEditView;
      }
      return groupsColumns;
    }
    if (teamType === "manual") {
      return usersColumns;
    }
  };

  const handleRemoveAddedUsers = () => {
    const filteredUsers = addedUsers.filter(
      (user) => !usersToRemoveSelected.includes(user),
    );
    setAddedUsers(filteredUsers);
    setUsersToRemoveSelected([]);
    setUsersData([...usersData, ...usersToRemoveSelected]);
    // setIsConfirmationModal(false);
  };

  const verifyUsersOriginalState = () => {
    return JSON.stringify(addedUsers) === JSON.stringify(addedUsersFromTeam);
  };

  const isTeamsButtonDisabled = () => {
    if (isRowClicked) {
      if (
        teamName === currentRowToEdit.name &&
        teamDescription === currentRowToEdit.description
      ) {
        const imageUrlSplit = imageUrlToUpload?.split("/")[4]?.split("%");
        const currentRowImageUrlSplit = currentRowToEdit.image_url
          ?.split("/")[4]
          ?.split("%");

        if (currentRowToEdit.image_url === null && imageUrlToUpload !== null) {
          return false;
        }

        if (teamType === "manual") {
          return verifyUsersOriginalState();
        }

        if (currentRowToEdit.image_url !== null && imageUrlToUpload !== null) {
          if (
            imageUrlSplit[2] + "/" + imageUrlSplit[3] ===
            currentRowImageUrlSplit[2] + "/" + currentRowImageUrlSplit[3]
          ) {
            return true;
          } else {
            return false;
          }
        }

        return false;
      }
    }
    if (
      teamName === "" ||
      (teamType === "manual" && addedUsers.length === 0) ||
      loadingImage
    ) {
      return true;
    }
  };

  const handleDeleteTeam = () => {
    deleteTeam("edit");
  };

  const handleTabChange = (index) => {
    setSelectedTab(tabs[index]);
  };

  useEffect(() => {
    if (teamType === "manual") {
      setTabs(tabsInitialState);
    } else {
      setTabs(tabs.filter((item) => item.id !== 2));
    }
  }, [teamType]);

  useEffect(() => {
    if (imageUrlToUpload !== null) {
      setLoadingImage(false);
    }
  }, [imageUrlToUpload]);

  useEffect(() => {
    if (emptyRows(page, rowsPerPage, addMembers) === rowsPerPage) {
      setSideMenuTablePage(page - 1);
    }
  }, [emptyRows(page, rowsPerPage, addMembers)]);

  useEffect(() => {
    dispatch(getGroupsData());
    dispatch(handleConnectionStatus());
  }, []);

  useEffect(() => {
    if (teamDeletedSuccess) {
      refreshStates();
    }
  }, [teamDeletedSuccess]);

  useEffect(async () => {
    if (Object.keys(currentRowToEdit).length > 0) {
      setTeamName(currentRowToEdit.name);
      setIsRowClicked(true);
      setTeamDescription(currentRowToEdit.description);
      setTeamtype(currentRowToEdit?.team_group[0]?.sync_type);
      const editMembers = currentRowToEdit?.team_group[0]?.group_members.map(
        (group) => group.user_profile,
      );
      setAddedUsers(editMembers);
      setAddedUsersFromTeam(editMembers);
      setTeamLead(currentRowToEdit?.team_manager?.user_id);
    } else {
      setIsRowClicked(false);
    }
  }, [currentRowToEdit]);

  useEffect(() => {
    if (isRowClicked && teamType === "manual") {
      getUsersList();
    }
  }, [isRowClicked]);

  useEffect(() => {
    if (!showAddMenu) {
      refreshStates();
    }
    if (isTeamCreatedButtonClick) {
      refreshStates();
    }
  }, [showAddMenu]);

  useEffect(() => {
    if (isConfirmationModal) {
      setUsersToAddConfirmed(
        addedUsers?.filter((user) => !addedUsersFromTeam.includes(user)),
      );
      setUsersToDeleteConfirmed(
        usersData.length > 0 &&
          usersData?.filter((user) => addedUsersFromTeam.includes(user)),
      );
    }
  }, [isConfirmationModal]);

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <Drawer
      anchor="right"
      open={showAddMenu}
      onClose={() => handleSideMenuClose()}
      classes={{ paper: "main-drawer" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <span className="head4 grey8" style={{ fontWeight: "400" }}>
              {isConfirmationModal ? (
                <Arrow_left
                  stroke="#343A40"
                  style={{
                    margin: "2px 20px 5px 0",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsConfirmationModal(false)}
                />
              ) : (
                <Close
                  stroke="#343A40"
                  strokeWidth="2"
                  style={{
                    margin: "0px 20px 3px 0",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleSideMenuClose}
                />
              )}
              {isRowClicked
                ? isTeamName
                  ? currentRow.name
                  : teamName
                : "Create new Team"}
            </span>

            {(selectedTab.id === 1 || selectedTab.id === 2) && (
              <div
                className="d-flex align-items-center flex-row-reverse"
                style={{ width: "fit-content" }}
              >
                {!isRowClicked &&
                usersToRemoveSelected.length === 0 &&
                !isConfirmationModal ? (
                  <>
                    <div style={{ width: "80px" }}>
                      {(!isNameActive && teamName === "") ||
                      teamType === undefined ||
                      loadingImage ||
                      (teamType == "auto"
                        ? Object.keys(currentRow).length === 0 || !isNameActive
                        : teamType === "manual" && addedUsers.length === 0) ? (
                        <Button
                          buttonFilledFull
                          typography="b1 grey1"
                          title="Next"
                          style={{
                            width: "96px",
                            height: "40px",
                            marginTop: "5px",
                            backgroundColor: "#7B2CBF66",
                            cursor: "no-drop",
                          }}
                          disabled={true}
                          teams
                        />
                      ) : (
                        <Button
                          buttonFilledLg
                          typography="body1-medium grey0"
                          title="Next"
                          loading={loading}
                          style={{
                            backgroundColor: "#9300FF",
                          }}
                          onClick={() => setIsConfirmationModal(true)}
                        />
                      )}
                    </div>
                    <div style={{ paddingRight: "15px" }}>
                      <Button
                        buttonHollowLg
                        typography="s1 grey6"
                        title="cancel"
                        onClick={handleSideMenuClose}
                        teams
                      />
                    </div>
                  </>
                ) : usersToRemoveSelected.length > 0 && tab2 ? (
                  <div
                    className="d-flex align-items-center flex-row-reverse"
                    style={{ width: "fit-content" }}
                  >
                    <Button
                      buttonFilledFull
                      title="Remove"
                      loading={loading}
                      style={{
                        color: "white",
                        width: "158px",
                        height: "40px",
                        marginTop: "5px",
                        fontWeight: "400",
                      }}
                      onClick={handleRemoveAddedUsers}
                      teams
                    />
                    <div style={{ paddingRight: "15px" }}>
                      <Button
                        buttonHollowLg
                        typography="s1 grey6"
                        title="cancel"
                        style={{ color: "#6C757D" }}
                        onClick={() => setUsersToRemoveSelected([])}
                        teams
                      />
                    </div>
                  </div>
                ) : isConfirmationModal ? (
                  <>
                    <div
                      className="d-flex align-items-center flex-row-reverse"
                      style={{ width: "fit-content" }}
                    >
                      <Button
                        title={
                          isRowClicked && teamType === "manual"
                            ? "Confirm Changes"
                            : "Confirm"
                        }
                        loading={loading}
                        buttonFilledLg
                        typography="body1-medium grey0"
                        style={{
                          backgroundColor: "#9300FF",
                        }}
                        onClick={
                          isRowClicked ? handleTeamEdit : handleNewTeamData
                        }
                      />
                      <div style={{ paddingRight: "15px" }}>
                        <Button
                          buttonHollowLg
                          typography="s1 grey6"
                          title="cancel"
                          style={{ color: "#6C757D" }}
                          onClick={() => setIsConfirmationModal(false)}
                          teams
                        />
                      </div>
                    </div>
                  </>
                ) : isRowClicked && !isEditableState ? (
                  <>
                    <div
                      style={{
                        cursor: "pointer",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <DotsThreeHorizontal onClick={handleThreeDotsOpen} />
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={isButtonActionMenu}
                        onClose={handleThreeDotsClose}
                      >
                        <MenuItem
                          disableRipple
                          onClick={() => {
                            // setIsDeleteCohortView(true);
                            handleDeleteTeam();
                          }}
                        >
                          <Fi_trash
                            stroke="#343A40"
                            style={{ width: "16px", height: "16px" }}
                          />
                          <span
                            className="s2 grey8 d-flex align-items-center"
                            style={{ marginLeft: "8px" }}
                          >
                            Delete
                          </span>
                        </MenuItem>
                      </StyledMenu>
                    </div>
                    <div style={{ marginRight: "16px" }}>
                      <PencilSimple
                        style={{
                          width: "32px",
                          height: "32px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsEditableState(true)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        title="Confirm"
                        buttonFilledLg
                        typography="body1-medium grey0"
                        loading={loading}
                        style={{
                          backgroundColor: "#9300FF",
                        }}
                        disabled={isTeamsButtonDisabled()}
                        onClick={() => {
                          setSideMenuTablePage(1);
                          setIsConfirmationModal(true);
                        }}
                      />
                    </div>
                    <div style={{ paddingRight: "15px" }}>
                      <Button
                        buttonHollowLg
                        typography="s1 grey6"
                        title="cancel"
                        onClick={() => setIsEditableState(false)}
                        teams
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {isEditableState ||
            (isRowClicked && (
              <div className="mt-4">
                <Tabv2
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onTabChange={handleTabChange}
                />
              </div>
            ))}

          {teamType === "manual" && !isConfirmationModal && (
            <div className="mt-4">
              <Tabv2
                tabs={tabs.slice(0, 2)}
                selectedTab={selectedTab}
                onTabChange={handleTabChange}
              />
            </div>
          )}
          {/* {selectedTab.id === 1 && ( */}
          <>
            {selectedTab.id === 1 && !isConfirmationModal ? (
              <div
                className={isRowClicked && !isEditableState && "disableEvents"}
              >
                <div style={{ flexWrap: "nowrap", paddingTop: "42px" }}>
                  <div>
                    {/* <div
                    className="head6 grey9"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    Teams Details
                  </div> */}

                    <div>
                      <div className="head7-medium grey8">Title</div>
                    </div>
                    <div
                      className={`${
                        !(isEditableState || !isRowClicked) && "disableField"
                      }`}
                      style={{ paddingBottom: "32px", paddingTop: "12px" }}
                    >
                      {/* <div className="col-md-8 col-lg-9 col-xl-10"> */}
                      <div>
                        <Field
                          className="b1 grey7"
                          inputFieldFull
                          onChange={handleTeamName}
                          value={isTeamName ? currentRow.name : teamName}
                          style={{
                            background:
                              isRowClicked && !isEditableState
                                ? "#F8F9FA"
                                : "#FFFFFF",
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ paddingBottom: "32px" }} className="d-flex">
                      <div className="col-6" style={{ paddingLeft: 0 }}>
                        <div
                          className="head7-medium grey8"
                          style={{ marginBottom: "12px" }}
                        >
                          Team Type
                        </div>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Field
                            className="s1"
                            placeholder="Select Team Type"
                            inputFieldFullReadOnly={isRowClicked}
                            teamTypeDropdown={!isRowClicked}
                            value={
                              isRowClicked
                                ? currentRowToEdit?.team_group[0]?.sync_type
                                : teamType
                            }
                            onClick={(value) => handleTeamType(value)}
                            handleTeamTypeMenu={handleTeamTypeMenu}
                            show={show}
                            options={["manual", "auto"]}
                            disabled={!isEditableState && isRowClicked}
                            style={{
                              color: isRowClicked
                                ? "rgba(73, 80, 87, 0.5)"
                                : "#495057",
                              cursor: "pointer",
                              background:
                                !isRowClicked && !isEditableState
                                  ? "#FFFFFF"
                                  : "#F8F9FA",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div
                          className="head7-medium grey8"
                          style={{ marginBottom: "12px" }}
                        >
                          Team Lead
                        </div>

                        <CustomSelect
                          items={allUsersData?.map((user) => {
                            return {
                              value: user.user_id, // Ensure this matches the expected value format
                              label: `${user.first_name} ${user.last_name}`,
                            };
                          })}
                          value={teamLead}
                          onChange={(e) => setTeamLead(e.target.value.value)}
                          disabled={!isEditableState && isRowClicked}
                          // error={error}
                          // helperText={helperText}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="head7-medium grey8">Description</div>
                <div
                  style={{
                    paddingTop: "12px",
                    paddingBottom: "24px",
                    borderBottom: "2px solid #E9ECEF",
                    borderColor: "#E9ECEF",
                  }}
                >
                  <Field
                    className="b1 grey8"
                    inputFieldFull
                    value={
                      isRowClicked && !isDescriptionActive
                        ? currentRowToEdit.description
                        : teamDescription
                    }
                    onChange={handleTeamDescription}
                    style={{
                      background:
                        isRowClicked && !isEditableState
                          ? "#F8F9FA"
                          : "#FFFFFF",
                    }}
                  />
                </div>

                {/* teamType === "manual" &&  */}
                {teamType != undefined && teamType === "manual" && (
                  <>
                    <div
                      className="head6-medium grey9"
                      style={{ paddingTop: "48px", paddingBottom: "20px" }}
                    >
                      Add Users to the Team
                    </div>
                    <div
                      className="teams-menu-container"
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                        background: "#F8F9FA",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Field
                        inputFieldFullExpand
                        placeholder="Search"
                        detectedUserIcon
                        style={{
                          background:
                            isRowClicked && !isEditableState
                              ? "#F8F9FA"
                              : "#FFFFFF",
                          height: "48px",
                          borderRadius: "10px",
                        }}
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        inputStyle={{
                          color: "#6C757D",
                          border: "none",
                          height: "48px",
                          background:
                            isRowClicked && !isEditableState
                              ? "#F8F9FA"
                              : "#FFFFFF",
                        }}
                      />
                    </div>
                  </>
                )}

                {/* --------------- WHEN TEAM TYPE IS AUTO --------------- */}
                {/* {((teamType == "auto" && teamType != undefined) || (isTeamEdit && teamType !== "manual")) && ( */}
                <>
                  {teamType == "auto" && (
                    <div
                      className="head6 grey9"
                      style={{ paddingTop: "48px", paddingBottom: "20px" }}
                    >
                      {isRowClicked
                        ? "Group Details"
                        : "Choose a Group to Autosync as Team"}
                    </div>
                  )}

                  {(teamType === "auto" || teamType === "manual") && (
                    <Paper
                      sx={{
                        width: "100%",
                        minWidth: "260px",
                        position: "relative",
                        boxShadow: "none",
                      }}
                    >
                      <>
                        {/* {teamType === "auto" ? getModalGroup() : getModalUser()} */}
                        {teamType === "auto" && getModalGroup()}

                        <Table
                          backgroundColor={
                            isRowClicked && !isEditableState
                              ? "#F8F9FA"
                              : "#FFFFFF"
                          }
                          data={getTableData()}
                          columns={getTableCells()}
                          loading={sideMenuTableLoading}
                          page={page}
                          inputValue={searchValue}
                          isTeamEdit={isRowClicked}
                          setTablePage={setSideMenuTablePage}
                          selected={addedUsersSelected}
                          setSelected={setAddedUsersSelected}
                          toolbarText={["Add Selected Users", "Add All Users"]}
                          handleToolbarAction={handleToolbarAction}
                          checkboxSize="24px"
                          setCurrentrow={setCurrentRow}
                          handleCurrentRow={handleCurrentRow}
                          open={openModal}
                          currentRow={currentRow}
                          handleOpen={handleOpenModal}
                          handleClickAddedUsers={handleClickAddedUsers}
                          handleRemoveFromTeam={handleRemoveFromTeam}
                          handlePopUpMenu={handlePopUpMenu}
                          setRowsPerPage={setSideMenuRowsPerPage}
                          teamsData={teamsData}
                          groupId={groupId}
                          inactiveGroups={inactiveGroups}
                          handleActionMenu={handleActionMenu}
                          handleActionMenuClose={handleActionMenuClose}
                          anchorEl={anchorEl}
                          checkboxRequired={
                            teamType === "manual" && usersData.length > 0
                          }
                          // teams
                          emptyStateText={
                            teamType === "manual"
                              ? "Users not found"
                              : "All groups are added as teams, no new groups found"
                          }
                          // background="rgb(248, 249, 250)"
                          teamsSideMenu
                          // addTeamsAuto={teamType === "auto" && "addTeamsAuto"}
                          // addTeamsManual={teamType === "manual" && "addTeamsManual"}
                          displayAddIconWithPopup={teamType === "auto"}
                          columnIndexToRenderIcon={1}
                          displayAvatar
                        />
                      </>
                    </Paper>
                  )}

                  {teamType == "auto" && (
                    <p className="b2 grey6" style={{ marginTop: "16px" }}>
                      {isRowClicked
                        ? "Teams created from autosynced groups cannot be edited."
                        : "Only one group be added at one time."}
                    </p>
                  )}
                </>
              </div>
            ) : selectedTab.id === 2 && !isConfirmationModal ? (
              <div
                className={isRowClicked && !isEditableState && "disableEvents"}
              >
                <div className="head6 grey9" style={{ margin: "24px 0" }}>
                  Remove Users
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    border: "1px solid #E9ECEF",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                  }}
                >
                  <Field
                    inputFieldFullExpand
                    placeholder="Search"
                    detectedUserIcon
                    style={{
                      background:
                        isRowClicked && !isEditableState
                          ? "#F8F9FA"
                          : "#FFFFFF",
                      height: "48px",
                      borderRadius: "10px",
                    }}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    inputStyle={{
                      color: "#6C757D",
                      border: "none",
                      height: "48px",
                      background:
                        isRowClicked && !isEditableState
                          ? "#F8F9FA"
                          : "#FFFFFF",
                    }}
                  />
                </div>
                <Table
                  backgroundColor={
                    isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF"
                  }
                  data={addedUsers.length > 0 && addedUsers}
                  inputValue={searchValue}
                  loading={sideMenuTableLoading}
                  columns={usersColumns}
                  selected={usersToRemoveSelected}
                  page={page}
                  setSelected={setUsersToRemoveSelected}
                  handleClickAddedUsers={handleClickCheckbox}
                  setTablePage={setSideMenuTablePage}
                  toolbarText={["Remove Selected Users", "Remove All Users"]}
                  handleToolbarAction={handleRemoveAddedUsers}
                  addTeamsManual
                  checkboxSize="24px"
                  emptyState="User not found"
                  checkboxRequired
                  displayAvatar
                />
              </div>
            ) : (
              isConfirmationModal && (
                <>
                  <div className="disableEvents">
                    <div
                      className="head6 grey8"
                      style={{ margin: "24px 0 16px 0" }}
                    >
                      {teamType === "auto"
                        ? "Auto sync Group As a Team"
                        : isRowClicked && teamType === "manual"
                          ? "Confirm Changes"
                          : "Confirm team creation"}
                    </div>

                    <div
                      className="s1 grey8"
                      style={{
                        marginBottom:
                          (usersToAddConfirmed.length === 0 ||
                            usersToDeleteConfirmed.length === 0) &&
                          "24px",
                      }}
                    >
                      {isRowClicked && teamType === "manual"
                        ? `You are about to add and remove users from ${teamName}. Are you sure you want to continue?`
                        : "Are you sure you want to continue?"}
                    </div>

                    {/* border here */}
                    {(usersToAddConfirmed.length > 0 ||
                      usersToDeleteConfirmed.length > 0) && (
                      <div
                        style={{
                          borderBottom: "2px solid #DEE2E6",
                          width: "100%",
                          marginBottom: "24px",
                          marginTop: "24px",
                        }}
                      />
                    )}

                    <div style={{ flexWrap: "nowrap" }}>
                      <div>
                        {!isRowClicked && (
                          <div
                            className="head6 grey9"
                            style={{ paddingBottom: "16px" }}
                          >
                            Teams Details
                          </div>
                        )}
                        <div
                          style={{ paddingBottom: "32px" }}
                          className="d-flex"
                        >
                          <div className="col-6">
                            <div>
                              <div className="s2 grey8">Title</div>
                            </div>
                            <div
                              // className="row"
                              style={{
                                paddingBottom: "16px",
                                paddingTop: "12px",
                              }}
                            >
                              <div>
                                <Field
                                  className="b1 grey7"
                                  inputFieldFull
                                  onChange={handleTeamName}
                                  value={
                                    isTeamName ? currentRow.name : teamName
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-6" style={{ paddingLeft: 0 }}>
                            <div
                              className="head7-medium grey8"
                              style={{ marginBottom: "12px" }}
                            >
                              Team Lead
                            </div>

                            <CustomSelect
                              items={allUsersData?.map((user) => {
                                return {
                                  value: user.user_id, // Ensure this matches the expected value format
                                  label: `${user.first_name} ${user.last_name}`,
                                };
                              })}
                              value={teamLead}
                              onChange={(e) =>
                                setTeamLead(e.target.value.value)
                              }
                              // error={error}
                              // helperText={helperText}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="s2 grey8">Description</div>
                        </div>
                        <div
                          // className="row"
                          style={{ paddingBottom: "32px", paddingTop: "12px" }}
                        >
                          <div>
                            <Field
                              className="b1 grey8"
                              inputFieldFull
                              value={
                                isRowClicked && !isDescriptionActive
                                  ? currentRowToEdit.description
                                  : teamDescription
                              }
                              onChange={handleTeamDescription}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* border here */}
                  {(usersToAddConfirmed.length > 0 ||
                    usersToDeleteConfirmed.length > 0) && (
                    <div
                      style={{
                        borderBottom: "2px solid #DEE2E6",
                        width: "100%",
                        marginBottom: "24px",
                        marginTop: "24px",
                      }}
                    />
                  )}

                  <div className="head6 grey9" style={{ marginBottom: "24px" }}>
                    {teamType === "auto"
                      ? "Group Details"
                      : !isConfirmationModal && "Users Details"}
                  </div>

                  {/* head  */}
                  {teamType === "auto" ? (
                    <div>
                      <div
                        className="head6 grey8 d-flex align-items-center"
                        style={{
                          height: "80px",
                          width: "100%",
                          paddingLeft: "32px",
                          backgroundColor: "#F8F9FA",
                          borderBottom: "1px solid #DEE2E6",
                        }}
                      >
                        Name
                      </div>

                      <div
                        className="b1 grey8 d-flex align-items-center"
                        style={{
                          height: "72px",
                          width: "100%",
                          paddingLeft: "20px",
                          backgroundColor: "#F8F9FA",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              margin: 0,
                              alignItems: "center",
                              marginLeft: "12px",
                            }}
                          >
                            <span
                              className="s1 grey1"
                              style={{
                                marginRight: "18px",
                                minWidth: "40px",
                                background: "#495057",
                                minHeight: "40px",
                                borderRadius: "100px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {teamType === "auto" && isRowClicked
                                ? currentRowToEdit?.team_group[0]?.name
                                    .split(" ")
                                    .map(function (item) {
                                      return item[0];
                                    })
                                    .join("")
                                : groupsData[0]?.name
                                    ?.split(" ")
                                    .map(function (item) {
                                      return item[0];
                                    })
                                    .join("")}
                            </span>
                            <span
                              className="b1 grey9"
                              style={{ display: "flex" }}
                            >
                              {teamType === "auto" && isRowClicked
                                ? currentRowToEdit?.team_group[0].name
                                : groupsData[0]?.name}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {usersToAddConfirmed.length > 0 && (
                        <>
                          <div
                            className="head6 grey8"
                            style={{ marginBottom: "20px" }}
                          >
                            Added Users
                          </div>
                          <Paper
                            sx={{
                              width: "100%",
                              minWidth: "260px",
                              position: "relative",
                              boxShadow: "none",
                            }}
                          >
                            <>
                              {teamType === "auto" && getModalGroup()}

                              <Table
                                backgroundColor={"#F8F9FA"}
                                customRowPerPage={usersData.length}
                                hidePagination
                                data={usersToAddConfirmed}
                                columns={getTableCells()}
                                page={page}
                                displayAvatar
                              />
                            </>
                          </Paper>
                        </>
                      )}

                      {usersToDeleteConfirmed.length > 0 && (
                        <>
                          <div
                            className="head6 grey8"
                            style={{ marginBottom: "20px" }}
                          >
                            Deleted Users
                          </div>
                          <Paper
                            sx={{
                              width: "100%",
                              minWidth: "260px",
                              position: "relative",
                              boxShadow: "none",
                            }}
                          >
                            <>
                              {teamType === "auto" && getModalGroup()}
                              <Table
                                backgroundColor={"#F8F9FA"}
                                customRowPerPage={usersData.length}
                                hidePagination
                                data={usersToDeleteConfirmed}
                                columns={getTableCells()}
                                page={page}
                                displayAvatar
                              />
                            </>
                          </Paper>
                        </>
                      )}
                    </>
                  )}
                </>
              )
            )}
          </>
          {/* // )} */}
          {(selectedTab.id === 3 && <AnalyticsSideBar />) ||
            (selectedTab.id === 4 && (
              <div>
                <SideBarSurveys />
              </div>
            )) ||
            (selectedTab.id === 5 && <SidebarObjectives />)}
        </div>
      </div>
    </Drawer>
  );
}
