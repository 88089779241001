import React, { useEffect, useState } from "react";
import { ReactComponent as List } from "../../../assets/icons/List.svg";
import { ReactComponent as Group } from "../../../assets/icons/SquaresFour.svg";
import ConnectionCard from "../../../components/Cards/ConnectionCards";
import { useDispatch, useSelector } from "react-redux";
import IntegrateSideMenu from "../../../components/SideMenus/IntegrateSideMenu";
import BackgroundBlur from "../../../components/sub-components/Blur";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import Filteration from "../../../components/sub-components/Filteration";
import { Table } from "../../../components/Tables/Table";
import { ReactComponent as Disconnected } from "../../../assets/icons/Disconnected.svg";
import { ReactComponent as Connected } from "../../../assets/icons/Connected.svg";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Field from "../../../components/sub-components/Field";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import { setTablePageAction } from "../../../redux/slices/tableSlice";
import {
  getAllIntegrationsStatusSlice,
  getIntegrationsDataSlice,
  meetConnectionSlice,
} from "../../../redux/slices/integrationsSlice";

export default function Integrations() {
  const dispatch = useDispatch();
  const { integrationsData, toolsStatus, loader } = useSelector(
    (state) => state.integrationsReducer,
  );
  const { page } = useSelector((state) => state.tableReducer);
  const [view, setView] = useState("list");
  const [rowValue, setRowValue] = useState({});
  // array of objects for all filters
  const [allFilters, setAllFilters] = useState([]);
  const [globalFiltersCount, setGlobalFiltersCount] = useState();
  const [mobileModal, setMobileModal] = useState(false);

  const [filterColumn, setFilterColumn] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filterInputValue, setFilterInputValue] = useState("");

  const mediaQuery = useWindowDimensions();
  const [selected, setSelected] = React.useState([]);
  const [filters, setFilters] = useState({});
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const actionMenuOpen = Boolean(anchorEl);

  const [userSelected, setUserSelected] = useState("");
  const [userApplied, setUserApplied] = useState("");

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  const columns = [
    {
      key: "name",
      name: "Tool",
      minWidth: 200,
      padding: "0 0 0 32px",
    },
    {
      key: "type",
      name: "Type",
      minWidth: 200,
    },
    {
      key: "category",
      name: "Category",
      minWidth: 200,
    },
    {
      key: "status",
      name: "Status",
      accessor: (row) => (
        <span className="b1 grey8">
          {/* row.status.toLowerCase() === "connected" */}
          {Object.prototype.hasOwnProperty.call(
            toolsStatus,
            row?.name?.toLowerCase(),
          ) ? (
            <div>
              <Connected style={{ marginRight: "10px" }} />
              <span className="b1 grey8">Connected</span>
            </div>
          ) : (
            <div>
              <Disconnected style={{ marginRight: "10px" }} />
              <span className="b1 grey8">Not Connected</span>
            </div>
          )}
        </span>
      ),
      minWidth: 200,
    },
  ];

  const handleConnection = () => {
    setIsSideMenuOpen(true);
  };

  const handleSideMenuClose = () => {
    setIsSideMenuOpen(false);
  };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleApplyUserType = () => {
    setUserApplied(userSelected);
    handleNestedFilterationMenuClose();
  };

  const handleCancelUserType = () => {
    handleNestedFilterationMenuClose();
    setUserSelected(userApplied);
  };

  const handleClickCheckbox = async (item) => {
    console.log("itemitem", item);
    console.log("userSelecteduserSelected", userSelected);
    if (item === userSelected) {
      setUserSelected("");
    } else {
      setUserSelected(item);
    }
  };

  useEffect(() => {
    if (isSideMenuOpen === true) {
      document.body.style.overflow = "hidden";
    } else if (isSideMenuOpen === false) {
      document.body.style.overflow = "unset";
    }
  }, [isSideMenuOpen]);

  useEffect(() => {
    getIntegrationsData();
    getAllIntegrationsStatus();
    meetConnection();
  }, []);

  useEffect(() => {
    setSelected(allFilters);
  }, [allFilters]);

  useEffect(() => {
    if (mobileModal) {
      document.body.style.overflow = "hidden";
    }
    if (!mobileModal) {
      document.body.style.overflow = "unset";
    }
  }, [mobileModal]);

  useEffect(() => {
    const uniqueKeys = Object.keys(filters).length;
    setGlobalFiltersCount(uniqueKeys);
  }, [filters, allFilters]);

  const getIntegrationsData = () => {
    dispatch(getIntegrationsDataSlice());
  };
  const getAllIntegrationsStatus = () => {
    const tools = ["github", "jira", "slack"];
    dispatch(getAllIntegrationsStatusSlice(tools));
  };

  const meetConnection = () => {
    const gAuthTypes = ["meet", "drive"];
    // const service = ["gauth"];
    dispatch(meetConnectionSlice(gAuthTypes, toolsStatus));
  };

  const handleCurrentRow = (row) => {
    setRowValue(row);
  };
  const handleActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const setTablePage = (data) => {
    dispatch(setTablePageAction(data));
  };

  const getActiveView = (activeView) =>
    view === activeView ? "#7B2CBF" : "#6C757D";

  const handleTableRowClick = (row) => {
    // props.handleActionMenu(event);
    handleCurrentRow(row);
    handleConnection();
    handleActionMenuClose();
  };

  return (
    <div style={{ minWidth: "300px" }}>
      <>
        {isSideMenuOpen && <BackgroundBlur closeMenu={handleSideMenuClose} />}
      </>

      <>
        <IntegrateSideMenu
          isSideMenuOpen={isSideMenuOpen}
          rowValue={rowValue}
          handleSideMenuClose={handleSideMenuClose}
          setIsSideMenuOpen={setIsSideMenuOpen}
          toolsStatus={toolsStatus}
        />
      </>

      {/* <div className="page-body-integrations flex-column"> */}
      <div
        className="page-body-new"
        style={{ display: "block", padding: "0 44px" }}
      >
        <div
          className="d-flex flex-row justify-content-end align-items-center"
          style={{ marginBottom: "50px" }}
        >
          <div className="d-flex justify-content-between w-100">
            <div>
              <span
                className="d-flex head4-medium grey8"
                // style={{ lineHeight: "2px" }}
              >
                Integrate your platforms
              </span>
              <span className="d-flex body1 grey6 mt-1">
                Connect multiple as you please
              </span>
            </div>
            {mediaQuery.width > 768 && (
              <div className="d-flex align-items-between">
                <>
                  <List
                    fill="none"
                    stroke={getActiveView("list")}
                    onClick={() => setView("list")}
                    style={{
                      marginRight: "12px",
                      cursor: "pointer",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <Group
                    fill="none"
                    stroke={getActiveView("group")}
                    style={{ cursor: "pointer", width: "40px", height: "40px" }}
                    onClick={() => setView("group")}
                  />
                </>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            border: view === "list" ? "1px solid #F5F5F5" : "none",
            paddingLeft: "27px",
            paddingRight: "27px",
            borderRadius: "10px",
            boxShadow:
              view === "list" ? "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
          }}
          // d-flex flex-column
          className="w-100"
        >
          <div
            className="d-flex align-items-center"
            style={{ padding: "20px 0" }}
          >
            <PopperDropdown
              anchorRef={nestedFilterAnchorEl}
              isDropdownOpen={isNestedFilterationMenuOpen}
              handleToggle={() => handleNestedFilterationMenuOpen()}
              handleClose={() => handleNestedFilterationMenuClose()}
              // dropdownDisplayText={capitalizeFirstAndRemoveLastTwoLetters(
              //   granularitySelected,
              // )}
              dropdownDisplayText="Users"
              customRenderFieldChildren={
                <div
                  className="d-flex align-items-center body1"
                  style={{
                    cursor: "pointer",
                    border: "1px solid #9300FF",
                    padding: "10px 12px 10px 12px",
                    borderRadius: "10px",
                    marginRight: "16px",
                  }}
                >
                  {userApplied === "" ? "Type" : userApplied}{" "}
                  <CaretDown stroke="black" stroke-width="2" className="ml-2" />
                </div>
              }
              // menuItems={["Users", "Event Types", "Sources"]}
              dropdownWithSearchAndCheckbox
              dropdownData={[
                ...new Set(integrationsData.map((data) => data.type)),
              ]}
              handleClickCheckbox={handleClickCheckbox}
              handleApply={handleApplyUserType}
              handleCancel={handleCancelUserType}
              dropdownItemsSelected={userSelected}
              // keyToAccess={currentViewApplied === "Teams" ? "name" : "none"}
              keyToAccess={"none"}
              // handleOptionSelected={handleGroupBySelected}
              // activeMenuItem={"Users"}
            />

            {/* <Field inputFieldFullIcon /> */}
            <Field
              inputFieldFullSearchIcon
              placeholder={`Search ${filterColumn}`}
              value={filterInputValue}
              onChange={(e) => setFilterInputValue(e.target.value)}
              style={{
                width: "100%",
                height: "40px",
                paddingLeft: "52px",
                borderRight: "1px solid #e9ecef",
                borderRadius: "10px",
                background: "white",
              }}
            />
          </div>

          {mediaQuery.width > 768 && view === "list" ? (
            <>
              <Table
                data={integrationsData}
                headerBackgroundColor="#F7FBFF"
                columns={columns}
                page={page}
                loading={loader}
                setTablePage={setTablePage}
                displayIcon
                toolsStatus={toolsStatus}
                inputValue={filterInputValue}
                filters={filters}
                columnIndexToSeach={0}
                handleTableRowClick={handleTableRowClick}
                displayImage
                columnIndexToRenderIcon={3}
                columnIndexToRenderImage={0}
              />
            </>
          ) : (
            <div>
              <ConnectionCard
                filters={filters}
                cardData={integrationsData}
                inputValue={inputValue}
                allFilters={allFilters}
                filterColumn={filterColumn.toLowerCase()}
                handleActionMenu={handleActionMenu}
                handleActionMenuClose={handleActionMenuClose}
                handleCurrentRow={handleCurrentRow}
                handleConnection={handleConnection}
                anchorEl={anchorEl}
                open={actionMenuOpen}
                toolsStatus={toolsStatus}
                integrations
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
