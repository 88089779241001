import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Utils from "../../utilities/Utils.js";
import { instance, secure_instance } from "../../axios/axios-config";
import { getTeamsSlice, getTenantIntegrations } from "./integrationsSlice";

const initialState = {
  isRefreshing: false,
  isRefreshed: false,
  userProfile: {},
  user: {
    accessToken: null,
    userId: null,
    tenantId: null,
    roleId: null,
    name: "",
    role: "",
  },
  loginForm: {
    email: "",
    password: "",
    isShowPassword: false,
    rememberMe: false,
  },
  loginFormErrors: {
    error: false,
    email: false,
    password: false,
  },
  registerForm: {
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    firstName: "",
    lastName: "",
    isShowPassword: false,
    isShowConfirmPassword: false,
    allowUsers: false,
  },
  registerFormErrors: {
    email: false,
    password: false,
    confirmPassword: false,
    companyName: false,
    firstName: false,
    lastName: false,
  },
  subscription: {
    stripePriceId: null,
    name: null,
    packageId: null,
    paymentMethodId: null,
  },
  stripePricings: [],
  subscriptionPackages: [],
  isPackagesModal: false,
  stripePaymentForm: {
    name: "",
    address: "",
    country: null,
    postalCode: "",
    city: "",
    state: "",
    markAsBusinessAddress: false,
  },
  stripePaymentFormErrors: {
    name: false,
    address: false,
    country: false,
    postalCode: false,
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
    city: false,
    state: false,
  },
  isAccountActivated: false,
  isRegistered: false,
  isSubscribed: false,
  couponCode: "",
  couponResult: {
    isCouponCodeApplied: false,
    couponErrorText: "",
    amountOff: null,
    percentOff: null,
  },
  tenantProfile: null,
  tenantSubscription: null,
  loading: false,
  forgotPasswordForm: {
    email: "",
  },
  forgotPasswordFormErrors: {
    email: false,
  },
  isResetPasswordLink: false,
  resetPasswordForm: {
    password: "",
    confirmPassword: "",
  },
  resetPasswordFormErrors: {
    password: false,
    confirmPassword: false,
  },
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
  isResetPasswordTokenValidated: false,
};

export const getCurrentUserData = (userId) => {
  return async (dispatch) => {
    try {
      const request_user_profile = await secure_instance.request({
        url: `v1/user_profile/${userId}`,
        method: "GET",
      });
      dispatch(setUserProfile(request_user_profile.data));
    } catch (e) {
      // dispatch(handleLoginFailed(e?.response?.data?.message));
    }
  };
};

export const getIsUserActive = (request, history) => {
  return async (dispatch) => {
    try {
      const request_user = await secure_instance.request({
        url: `v1/users/${request.data.user_id}`,
        method: "GET",
      });
      // const request_user_profile = await secure_instance.request({
      //   url: `v1/user_profile/${request.data.user_id}`,
      //   method: "GET",
      // });
      // dispatch(setUserProfile(request_user_profile.data));
      if (!request_user.data.is_active) {
        history.push("/activation");
        return;
      }
      let gauthReq = null;
      try {
        gauthReq = await secure_instance.request({
          url: "v1/gauth/connection",
          method: "Get",
        });
      } catch (e) {
        history.push("/identity-providers-list");
      }
      if (gauthReq.data.auto_sync) {
        const teams_result = await dispatch(getTeamsSlice(history));
        if (teams_result == 1) {
          const tenant_result = await dispatch(getTenantIntegrations(history));

          if (tenant_result == 1) {
            history.push("/dashboard");
          } else {
            history.push("/integrations");
          }
        } else {
          history.push("/identity-providers-list");
        }
      } else {
        history.push("/integrations");
      }
      dispatch(handleLoginEnd());
    } catch (e) {
      dispatch(handleLoginFailed(e?.response?.data?.message));
    }
  };
};

export const handleLoginSliceCall = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(handleLoginStart());
      const request = await instance.request({
        url: "v1/auth/login",
        method: "Post",
        data,
      });
      Utils.setCookie(
        // Constant.tokenCookieName,
        "rftkn",
        request.data.refresh_token,
        data.rememberMe ? 7 : 1,
      );
      dispatch(handleLogin(request.data));
      dispatch(getIsUserActive(request, history));
    } catch (e) {
      dispatch(handleLoginFailed(e?.response?.data?.message));
    }
  };
};

export const handleRegisterSliceCall = (data) => {
  return async (dispatch) => {
    try {
      dispatch(handleRegisterCallStart());

      const request = await instance.request({
        url: "v1/auth/register",
        method: "Post",
        data: {
          first_name: data.firstName,
          last_name: data.lastName,
          company_name: data.companyName,
          email: data.email,
          password: data.password,
          allow_users_signin: data.allowUsers,
        },
      });
      Utils.setCookie(
        // Constant.tokenCookieName,
        "rftkn",
        request.data.refresh_token,
        data.remember_me ? 7 : 1,
      );
      Utils.setCookie(
        // Constant.tokenCookieName,
        "email",
        data.email,
        1, //this is temporary
      );
      window.location.replace("/activation");
    } catch (e) {
      if (e.response.status === 400 || e.response.status === 409) {
        if (e.response.data.message === "Invalid Password") {
          dispatch(invalidPassword(e?.response?.data?.message));
        } else {
          dispatch(invalidEmailAddress(e?.response?.data?.message));
        }
      }
    }
  };
};

// export const refreshTokenSliceCall = () => {
//   return async (dispatch) => {
//     try {
//       const request = await instance.request({
//         url: "v1/auth/refresh",
//         method: "post",
//         data: { refresh_token: Utils.getCookie("rftkn") },
//       });
//       if (!request.data.is_active) {
//         window.location.replace("/activation");
//       }

//       dispatch(refreshToken(request));
//       const email = Utils.getCookie("email");
//       if (!email) {
//         const request_user = await secure_instance.request({
//           url: `v1/users/${request.data.user_id}`,
//           method: "GET",
//         });
//         Utils.setCookie("email", request_user.data.email, 1);
//       }
//     } catch (e) {
//       window.location.href = "/";
//     }
//   };
// };

// Thunk for refreshing token
export const refreshTokenSliceCall = createAsyncThunk(
  "auth/refreshToken",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const request = await instance.request({
        url: "v1/auth/refresh",
        method: "post",
        data: { refresh_token: Utils.getCookie("rftkn") },
      });
      if (!request.data.is_active) {
        window.location.replace("/activation");
        return rejectWithValue("User not active");
      }
      dispatch(refreshToken(request));
      const email = Utils.getCookie("email");
      // const userName = Utils.getCookie("userName");
      if (!email) {
        const request_user = await secure_instance.request({
          url: `v1/users/${request.data.user_id}`,
          method: "GET",
        });
        // dispatch(setUserName(request_user.data));
        Utils.setCookie("email", request_user.data.email, 1);
        // Utils.setCookie(
        //   "userName",
        //   `${request_user.data.first_name} ${request_user.data.last_name}`,
        //   1,
        // );
      }

      if (!initialState.userProfile) {
        const request_user_profile = await secure_instance.request({
          url: `v1/user_profile/${request.data.user_id}`,
          method: "GET",
        });
        dispatch(setUserProfile(request_user_profile.data));
      }

      return request.data;
    } catch (e) {
      window.location.href = "/";
      return rejectWithValue(e.message);
    }
  },
);

export const getSubscriptionPackagesSliceCall = () => {
  return async (dispatch) => {
    try {
      const request = await instance.request({
        url: "v1/subscription_packages/",
        method: "Get",
      });
      dispatch(getSubscriptionPackages(request.data));
    } catch (e) {
      //
    }
  };
};

export const getStripePricingsSliceCall = () => {
  return async (dispatch) => {
    try {
      const request = await instance.request({
        url: "v1/stripe_products/",
        method: "Get",
      });
      dispatch(getStripePricings(request.data));
    } catch (e) {
      //
    }
  };
};

export const createStripePaymentMethod = (
  stripe,
  elements,
  CardNumberElement,
  data,
  subscription,
  tenantId,
) => {
  return async (dispatch) => {
    try {
      dispatch(createSubscriptionStart());
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data.name,
          address: {
            country: "US",
            postal_code: data.postalCode,
            line1: data.address,
            // city: data.city,
            // state: data.state,
          },
        },
      });
      await secure_instance.request({
        url: "v1/subscriptions/",
        method: "Post",
        data: {
          name_on_card: data.name,
          // package_id: subscription.packageId,
          stripe_pricing_id: subscription.stripePriceId,
          payment_method_id: paymentMethod.paymentMethod.id,
          coupon: null,
        },
      });

      // if (data.markAsBusinessAddress) {
      //   await secure_instance.request({
      //     url: `v1/tenant_profiles/${tenantId}`,
      //     method: "Patch",
      //     data: {
      //       address: data.address + ", " + data.city,
      //       state: data.state,
      //       zip_code: data.postalCode,
      //     },
      //   });
      // }

      dispatch(createSubscriptionEnd());
    } catch (e) {
      dispatch(createSubscriptionFailed());
    }
  };
};

export const activateAccountSliceCall = (token) => {
  return async (dispatch) => {
    try {
      await instance.request({
        url: "v1/auth/activate",
        method: "Post",
        data: {
          token,
        },
      });

      dispatch(activateAccount());
      dispatch(refreshTokenSliceCall());
    } catch (e) {
      dispatch(activateLinkExpired());
    }
  };
};

export const resendActivationLink = (email) => {
  return async () => {
    try {
      await instance.request({
        url: "v1/auth/activate/request",
        method: "Post",
        data: {
          email,
        },
      });
    } catch (e) {
      //
    }
  };
};

// export const getTenantProfileSliceCall = (tenantId) => {
//   return async (dispatch) => {
//     try {
//       const request = await secure_instance.request({
//         url: `v1/tenant_profiles/${tenantId}`,
//         method: "Get",
//       });
//       dispatch(getTenantProfile(request.data));
//     } catch (e) {
//       //
//     }
//   };
// };

export const getTenantSubscriptionSliceCall = (tenantId) => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: `v1/subscriptions/${tenantId}`,
        method: "Get",
      });
      dispatch(getTenantSubscription(request.data));
    } catch (e) {
      //
    }
  };
};

export const checkCouponDetails = (couponCode) => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: `v1/stripe_customers/coupon_details/${couponCode}`,
        method: "Get",
      });
      dispatch(checkCouponCode(request.data));
    } catch (e) {
      dispatch(checkCouponCodeError(e?.response?.data));
    }
  };
};

export const resetPasswordRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequestStart());

      await instance.request({
        url: "v1/auth/resetpassword/request",
        method: "Post",
        data,
      });
      dispatch(resetPasswordRequestEnd());
    } catch (e) {
      dispatch(resetPasswordRequestFailed(e?.response?.data?.message));
    }
  };
};

export const validateResetPasswordToken = (token) => {
  return async (dispatch) => {
    try {
      await instance.request({
        url: `v1/auth/resetpassword/validate?token=${token}`,
        method: "Get",
      });
      dispatch(validateResetPasswordTokenEnd());
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e?.response?.data?.message,
          severity: "error",
        }),
      );
      dispatch(validateResetPasswordTokenFailed());
    }
  };
};

export const resetPassword = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordStart());
      await instance.request({
        url: "v1/auth/resetpassword",
        method: "Patch",
        data,
      });
      dispatch(resetPasswordEnd());
      history.push("/login");
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e?.response?.data?.message,
          severity: "error",
        }),
      );
      dispatch(resetPasswordFailed(e?.response?.data?.message));
    }
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleLoginFormChange: (state, action) => {
      const { name, value } = action.payload;
      state.loginForm[name] = value;
    },
    toggleRememberMeCheck: (state, action) => {
      state.loginForm.rememberMe = action.payload;
    },
    handleLoginStart: (state) => {
      state.loading = true;
      state.loginFormErrors.error = false;
    },
    setUserProfile: (state, action) => {
      const userProfile = action.payload;
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
      state.userProfile = userProfile;
    },
    handleLogin: (state, action) => {
      const { access_token, user_id, tenant_id, role_id } = action.payload;
      state.user = {
        ...state.user,
        accessToken: access_token,
        userId: user_id,
        tenantId: tenant_id,
        roleId: role_id,
      };
    },
    handleLoginEnd: (state) => {
      state.loading = false;
      state.loginFormErrors = initialState.loginFormErrors;
    },
    handleLoginFailed: (state, action) => {
      state.loading = false;
      state.loginFormErrors.error = action.payload;
    },
    toggleLoginEyeClick: (state) => {
      state.loginForm.isShowPassword = !state.loginForm.isShowPassword;
    },
    handleRegisterCallFormChange(state, action) {
      const { name, value } = action.payload;
      state.registerForm[name] = value;
    },
    toggleRegisterEyeClick(state) {
      state.registerForm.isShowPassword = !state.registerForm.isShowPassword;
    },
    toggleConfirmRegisterEyeClick(state) {
      state.registerForm.isShowConfirmPassword =
        !state.registerForm.isShowConfirmPassword;
    },
    handleRegisterCallStart(state) {
      state.loading = true;
    },
    handleRegisterCall(state, action) {
      const { data } = action.payload;
      // localStorage.setItem("email", state.registerForm.email);
      Utils.setCookie("email", state.registerForm.email, 1);
      // Reset registerForm and update user data
      state.registerForm = initialState.registerForm;
      state.isRegistered = true;
      state.user.accessToken = data.access_token;
      state.user.userId = data.user_id;
      state.user.tenantId = data.tenant_id;
      state.user.roleId = data.role_id;
      state.loading = false;
    },
    refreshToken(state, action) {
      const { data } = action.payload;
      state.user.accessToken = data.access_token;
      state.user.userId = data.user_id;
      state.user.tenantId = data.tenant_id;
      state.user.roleId = data.role_id;
      state.user.role = data.roles[0].name;
    },
    validateRegisterForm(state, action) {
      const { name, value } = action.payload;
      state.registerFormErrors[name] = value;
    },
    invalidEmailAddress(state, action) {
      const { data } = action.payload;
      state.registerFormErrors.email = data;
      state.loading = false;
    },

    invalidPassword(state, action) {
      const { data } = action.payload;
      state.registerFormErrors.password = data;
      state.loading = false;
    },

    getSubscriptionPackages(state, action) {
      const { data } = action.payload;
      state.subscriptionPackages = data;
    },

    getStripePricings(state, action) {
      // const { data } = action.payload;
      console.log("data in redux", action.payload);
      state.stripePricings = action.payload;
    },

    toggleModal(state) {
      state.isPackagesModal = !state.isPackagesModal;
    },

    setStripePriceId(state, action) {
      const { stripePriceId } = action.payload;
      state.subscription.stripePriceId = stripePriceId;
    },

    handleStripeFormChange(state, action) {
      const { name, value } = action.payload;
      state.stripePaymentForm[name] = value;
    },
    handleResetStripeForm(state) {
      state.stripePaymentForm = { ...initialState.stripePaymentForm };
    },

    handleStripeFormSelectChange(state, action) {
      // const { data } = action.payload;
      state.stripePaymentForm.country = action.payload;
    },

    activateAccount(state) {
      state.isAccountActivated = true;
    },

    createSubscriptionStart(state) {
      state.loading = true;
    },

    createSubscriptionFailed(state) {
      state.loading = false;
    },
    createSubscriptionEnd(state) {
      state.loading = false;
      state.isSubscribed = true;
    },

    handleCouponCodeChange(state, action) {
      const { data } = action.payload;
      state.couponCode = data.value;
    },

    validateStripeForm(state, action) {
      // const { data } = action.payload;
      const { name, value } = action.payload;
      state.stripePaymentFormErrors[name] = value;
    },

    getTenantProfile(state, action) {
      const { data } = action.payload;
      state.tenantProfile = data;
    },

    getTenantSubscription(state, action) {
      const { data } = action.payload;
      state.tenantSubscription = data;
    },

    checkCouponCode(state, action) {
      const { data } = action.payload;
      state.couponResult = {
        ...state.couponResult,
        isCouponCodeApplied: data.valid ? true : false,
        couponErrorText: data.valid ? "" : "Coupon Code not valid",
        amountOff: data?.amount_off,
        percentOff: data?.percent_off,
      };
    },

    removeCoupon(state) {
      state.couponResult = initialState.couponResult;
      state.couponCode = initialState.couponCode;
    },

    checkCouponCodeError(state, action) {
      const { data } = action.payload;
      state.couponResult.couponErrorText = data;
    },
    activateLinkExpired(state) {
      state.isAccountActivated = false;
    },

    setLoginFormErrors(state, action) {
      const { name, value } = action.payload;
      state.loginFormErrors[name] = value;
    },

    setForgotPasswordForm(state, action) {
      const { name, value } = action.payload;
      state.forgotPasswordForm[name] = value;
    },

    setForgotPasswordFormErrors(state, action) {
      const { name, value } = action.payload;
      state.forgotPasswordFormErrors[name] = value;
    },

    resetPasswordRequestStart(state) {
      state.loading = true;
    },

    resetPasswordRequestFailed(state, action) {
      const { email } = action.payload;
      state.loading = false;
      state.forgotPasswordFormErrors.email = email;
    },

    resetPasswordRequestEnd(state) {
      state.loading = false;
      state.isResetPasswordLink = true;
    },

    setResetPasswordForm(state, action) {
      const { name, value } = action.payload;
      state.resetPasswordForm[name] = value;
    },

    setResetPasswordFormErrors(state, action) {
      const { name, value } = action.payload;
      state.resetPasswordFormErrors[name] = value;
    },

    validateResetPasswordTokenEnd(state) {
      state.isResetPasswordTokenValidated = true;
    },

    validateResetPasswordTokenFailed(state) {
      state.isResetPasswordTokenValidated = false;
    },

    resetPasswordFailed(state, action) {
      const { password } = action.payload;
      state.loading = false;
      state.resetPasswordFormErrors.password = password;
    },

    resetPasswordStart(state) {
      state.loading = true;
    },

    resetPasswordEnd(state) {
      state.loading = false;
    },
    setUserName(state, action) {
      const { data } = action.payload;
      state.user.name = data.first_name + data.last_name;
    },
    showSnackbar: (state, action) => {
      state.snackbar.open = true;
      state.snackbar.message = action.payload.message;
      state.snackbar.severity = action.payload.severity || "info";
    },
    hideSnackbar: (state) => {
      state.snackbar.open = false;
      state.snackbar.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenSliceCall.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(refreshTokenSliceCall.fulfilled, (state) => {
        state.isRefreshing = false;
        state.isRefreshed = true;
      })
      .addCase(refreshTokenSliceCall.rejected, (state) => {
        state.isRefreshing = false;
      });
  },
});

export const {
  handleLoginFormChange,
  toggleRememberMeCheck,
  handleLoginStart,
  setUserProfile,
  handleLogin,
  handleLoginEnd,
  handleLoginFailed,
  toggleLoginEyeClick,
  handleRegisterCallFormChange,
  toggleRegisterEyeClick,
  toggleConfirmRegisterEyeClick,
  handleRegisterCallStart,
  handleRegisterCall,
  refreshToken,
  validateRegisterForm,
  invalidEmailAddress,
  invalidPassword,
  getSubscriptionPackages,
  getStripePricings,
  toggleModal,
  setStripePriceId,
  handleStripeFormChange,
  handleResetStripeForm,
  handleStripeFormSelectChange,
  activateAccount,
  createSubscriptionStart,
  createSubscriptionFailed,
  createSubscriptionEnd,
  handleCouponCodeChange,
  validateStripeForm,
  getTenantProfile,
  getTenantSubscription,
  checkCouponCode,
  removeCoupon,
  checkCouponCodeError,
  activateLinkExpired,
  setLoginFormErrors,
  setForgotPasswordForm,
  setForgotPasswordFormErrors,
  resetPasswordRequestStart,
  resetPasswordRequestFailed,
  resetPasswordRequestEnd,
  setResetPasswordForm,
  setResetPasswordFormErrors,
  validateResetPasswordTokenEnd,
  validateResetPasswordTokenFailed,
  resetPasswordFailed,
  resetPasswordStart,
  resetPasswordEnd,
  setUserName,
  showSnackbar,
  hideSnackbar,
} = authSlice.actions;

export default authSlice.reducer;
