import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowsLeftRight } from "../../assets/icons/ArrowsLeftRight.svg";
import { ReactComponent as CrewneticsSymbol } from "../../assets/icons/Logo/crewnetics-logo.svg";
import { ReactComponent as Connected } from "../../assets/icons/Connected.svg";
import { ReactComponent as Disconnected } from "../../assets/icons/Disconnected.svg";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CircularSuccess } from "../../assets/icons/circularSuccess.svg";
import Field from "../../components/sub-components/Field";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import { ReactComponent as DotsThreeHorizontal } from "../../assets/icons/DotsThreeHorizontal.svg";
import { MenuItem } from "@mui/material";
import CircularLoader from "../Loading/CircularLoader";
import { Table } from "../Tables/Table";
import { ReactComponent as Info } from "../../assets/icons/Info.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Map } from "../../assets/icons/Map.svg";
import { StyledMenu } from "../Menus/Menu";
import {
  connections,
  getActiveUsers,
  getConnectedUsers,
  getDetectedUsers,
  handleDisconnectSlice,
  handleUpdateDetectedUsers,
  meetDisconnect,
  meetWebhookRegistraion,
  setSideMenuTablePage,
  toggleDisconnectSuccess,
  toggleIsUserMapped,
} from "../../redux/slices/integrationsSlice";

export default function IntegrateSideMenu(props) {
  const dispatch = useDispatch();
  const { isSideMenuOpen, rowValue } = props;

  const {
    disconnectionLoader,
    disconnectSuccess,
    isLoading,
    isUserMapped,
    tableLoader,
  } = useSelector((state) => state.integrationsReducer);

  const [currentTab, setCurrentTab] = useState(1);
  const [usersSearch, setUsersSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuItem = Boolean(anchorEl);
  const [isUserInformationView, setIsUserInformationView] = useState(false);
  const [IsDetectedUserView, setIsDetectedUserView] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);
  const [isDisconnectView, setIsDisconnectView] = useState(false);
  const [currentActiveUser, setCurrentActiveUser] = useState(false);
  const [mapUserView, setMapUserView] = useState(false);
  const [currentRowId, setCurrentRowId] = React.useState(null);

  const isCurrentRowConnected = Object.prototype.hasOwnProperty.call(
    props.toolsStatus,
    rowValue?.name?.toLowerCase(),
  );

  const { data, page } = useSelector(
    (state) => state.integrationsReducer.sideMenu.tableData,
  );

  const columns = [
    {
      key: "name",
      name: "Name",
      minWidth: 300,
      padding: "0 0 0 32px",
      accessor: (row) => `${row.first_name}  ${row.last_name}`,
    },
    {
      key: "_",
      name: "Department",
      minWidth: 270,
    },
    {
      key: "icon",
      align: "right",
      name: "",
      minWidth: 150,
      accessor: (row) => (
        <span
          className="d-flex justify-content-end b1 mr-2 grey8"
          style={{ gap: "21px" }}
        >
          {currentRowId === row.user_id && (
            <Info
              stroke="#7B2CBF"
              strokeWidth="2.5"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
          )}
        </span>
      ),
    },
  ];

  const columnsDetectedUsers = [
    {
      key: "username",
      name: "Name",
      minWidth: 300,
      padding: "0 0 0 32px",
    },
    {
      key: "icon",
      align: "right",
      name: "",
      // padding: "16px 32px",
      minWidth: 150,
      accessor: (row) => (
        <div className="d-flex" style={{ marginRight: "32px" }}>
          {currentRowId === (mapUserView ? row.user_id : row.id) &&
          mapUserView ? (
            <Map
              style={{ width: "24px", height: "24px", marginRight: "16px" }}
            />
          ) : (
            currentRowId === (mapUserView ? row.user_id : row.id) && (
              <Settings
                stroke="#7B2CBF"
                strokeWidth="2.5"
                style={{ width: "24px", height: "24px" }}
              />
            )
          )}
        </div>
      ),
    },
  ];

  const mapUserColumns = [
    {
      key: "name",
      name: "Name",
      minWidth: 300,
      padding: "0 0 0 32px",
      accessor: (row) => `${row.first_name}  ${row.last_name}`,
    },
    {
      key: "icon",
      align: "right",
      name: "",
      // padding: "16px 32px",
      minWidth: 150,
      accessor: (row) => (
        <div className="d-flex" style={{ marginRight: "32px" }}>
          {currentRowId === row.user_id && (
            <>
              <Map
                style={{ width: "24px", height: "24px", marginRight: "16px" }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  const setTablePage = (data) => {
    dispatch(setSideMenuTablePage(data));
  };

  const handleChangeConnect = () => {
    const gApp = ["meet", "drive"];
    const type = rowValue.name.toLowerCase();
    if (gApp.includes(type)) {
      dispatch(meetWebhookRegistraion(type));
    } else {
      dispatch(connections(type));
    }
  };
  const handleActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDisconnectView = () => {
    setIsDisconnectView(false);
    dispatch(toggleDisconnectSuccess());
  };
  const handleUserInformation = (row) => {
    setUsersSearch("");
    setIsUserInformationView(true);
    setCurrentRow(row);
  };

  const handleDetectedUserClick = (row) => {
    setUsersSearch("");
    setIsDetectedUserView(true);
    setCurrentRow(row);
    setTablePage(1);
  };

  const handleDisconnect = () => {
    const gApp = ["meet", "drive"];
    const type = rowValue.name.toLowerCase();
    if (gApp.includes(type)) {
      dispatch(meetDisconnect(type));
    } else {
      dispatch(handleDisconnectSlice(type));
    }
  };

  const handleActiveUserToMap = (row) => {
    setMapUserView(true);
    setCurrentActiveUser(row);
  };

  const handleNavigateBack = () => {
    setUsersSearch("");
    if (isUserInformationView) {
      setIsUserInformationView(false);
    }
    if (IsDetectedUserView && !mapUserView) {
      setIsDetectedUserView(false);
    }
    if (mapUserView) {
      setMapUserView(false);
    }
    if (isDisconnectView) {
      setIsDisconnectView(false);
      if (disconnectSuccess) {
        dispatch(toggleDisconnectSuccess());
      }
    }
    setTablePage(1);
  };

  const handleMapUser = async () => {
    const primaryIdentifier = currentActiveUser.identifiers?.find(
      (identifier) => identifier.is_primary,
    );

    const mapUser = [
      {
        user_id: currentActiveUser.user_id,
        type: currentRow.integration?.category,
        identifier: currentRow.identifier,
        identifier_id: currentRow.identifier_id,
        email:
          currentRow.identifier.toLowerCase() === "github"
            ? currentActiveUser.first_name + " " + currentActiveUser.last_name
            : primaryIdentifier?.email,
        is_primary: false,
      },
      {
        id: currentRow.id,
        tenant_id: currentRow.tenant_id,
        identifier: currentRow.identifier,
        identifier_id: currentRow.identifier_id,
        username: currentRow.username,
        is_mapped: true,
      },
    ];

    dispatch(handleUpdateDetectedUsers(mapUser));

    refershState();
  };

  const handleIconShow = (id) => {
    if (currentRowId === id) {
      return;
    }
    setCurrentRowId(id);
  };

  const refershState = () => {
    setCurrentTab(1);
    setMapUserView(false);
    setIsUserInformationView(false);
    setIsDetectedUserView(false);
    dispatch(setSideMenuTablePage(1));
    setUsersSearch("");
  };

  useEffect(() => {
    if (isSideMenuOpen === false) {
      refershState();
    }
  }, [isSideMenuOpen]);

  useEffect(() => {
    if (currentTab === 2) {
      let toolName =
        rowValue.name.charAt(0).toUpperCase() +
        rowValue.name.slice(1).toLowerCase();
      const tools = ["Meet", "Drive"];
      if (tools.includes(toolName)) {
        toolName = "google";
      }
      dispatch(getConnectedUsers(toolName));
    }
    if (currentTab === 3 && !IsDetectedUserView) {
      let toolName =
        rowValue.name.charAt(0).toUpperCase() +
        rowValue.name.slice(1).toLowerCase();
      const tools = ["Meet", "Drive"];
      if (tools.includes(toolName)) {
        toolName = "google";
      }
      dispatch(getDetectedUsers(toolName));
    }
  }, [currentTab, IsDetectedUserView]);

  useEffect(() => {
    if (IsDetectedUserView && !mapUserView) {
      const toolName = rowValue.name;
      dispatch(getActiveUsers(toolName));
    }
  }, [IsDetectedUserView]);

  useEffect(() => {
    if (isUserMapped) {
      setMapUserView(false);
      setIsUserInformationView(false);
      setIsDetectedUserView(false);
      dispatch(setSideMenuTablePage(1));
      setUsersSearch("");
      dispatch(toggleIsUserMapped());
    }
  }, [isUserMapped]);

  return (
    <div
      className={`side-menu-box ${
        isSideMenuOpen === true ? "open border" : "side-menu-box border"
      }`}
      style={{ padding: "48px" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <span
          className="head5 grey8 d-flex align-items-center"
          style={{ marginBottom: "24px" }}
        >
          {isUserInformationView || IsDetectedUserView || isDisconnectView ? (
            <Arrow_left
              stroke={disconnectionLoader ? "#ADB5BD" : "#343A40"}
              style={{
                pointerEvents: disconnectionLoader && "none",
                margin: "0px 20px 0px 0",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={() => handleNavigateBack()}
            />
          ) : (
            <Close
              stroke="#343A40"
              strokeWidth="2"
              style={{
                margin: "0px 20px 3px 0",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={() => props.handleSideMenuClose()}
            />
          )}

          {isDisconnectView
            ? "Disconnect Integration"
            : isUserInformationView
              ? "User Infomation"
              : IsDetectedUserView || mapUserView
                ? "Manage Detected User"
                : "Integrations"}
        </span>
        {!IsDetectedUserView &&
          !isDisconnectView &&
          isCurrentRowConnected &&
          !isUserInformationView && (
            <div style={{ cursor: "pointer", width: "40px", height: "40px" }}>
              <DotsThreeHorizontal
                style={{ marginTop: "-15px" }}
                onClick={handleActionMenu}
              />
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                style={{ maxWidth: "112px" }}
                anchorEl={anchorEl}
                open={isMenuItem}
                onClose={handleActionMenuClose}
              >
                <MenuItem disableRipple>
                  <span className="s2 grey8 d-flex align-items-center">
                    Edit
                  </span>
                </MenuItem>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleActionMenuClose();
                    setIsDisconnectView(true);
                  }}
                >
                  <span className="s2 grey8 d-flex align-items-center">
                    Disconnect
                  </span>
                </MenuItem>
              </StyledMenu>
            </div>
          )}

        {!isCurrentRowConnected &&
          !disconnectionLoader &&
          !disconnectSuccess && (
            <div
              className="d-flex align-items-center flex-row-reverse"
              style={{ width: "fit-content" }}
            >
              <div
                className="integration-menu-buttons b0-medium grey1"
                style={{ marginTop: "-25px" }}
              >
                <Button
                  buttonFilledFull
                  title="Connect"
                  style={{ color: "white", height: "40px", fontWeight: "400" }}
                  loading={isLoading}
                  onClick={() => handleChangeConnect()}
                />
              </div>
              <div
                style={{ paddingRight: "15px", marginTop: "-25px" }}
                className="integration-menu-buttons"
              >
                <Button
                  buttonHollowLg
                  title="Cancel"
                  style={{ height: "40px" }}
                  onClick={() => props.handleSideMenuClose()}
                />
              </div>
            </div>
          )}

        {mapUserView && (
          <div
            className="d-flex align-items-center flex-row-reverse"
            style={{ width: "fit-content" }}
          >
            <div className="integration-menu-buttons">
              <Button
                buttonFilledFull
                title="Map User"
                className="s1 grey1"
                style={{
                  color: "white",
                  height: "40px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
                loading={isLoading}
                onClick={() => handleMapUser()}
              />
            </div>
            <div
              style={{ paddingRight: "15px" }}
              className="integration-menu-buttons"
            >
              <Button
                buttonHollowLg
                title="Cancel"
                className="s1 grey6"
                style={{ height: "40px", marginTop: "5px" }}
                onClick={() => setMapUserView(false)}
              />
            </div>
          </div>
        )}

        {disconnectSuccess ? (
          <div
            className="integration-menu-buttons"
            style={{ marginTop: "-25px" }}
          >
            <Button
              buttonFilledFull
              title="Close"
              style={{
                color: "white",
                height: "40px",
                fontWeight: "400",
                width: "86px",
              }}
              // loading={loading}
              onClick={() => {
                props.handleSideMenuClose();
                handleCloseDisconnectView();
              }}
            />
          </div>
        ) : (
          isDisconnectView && (
            <div
              className="d-flex align-items-center flex-row-reverse"
              style={{ width: "fit-content" }}
            >
              <div
                className="integration-menu-buttons"
                style={{ marginTop: "-26px" }}
              >
                <Button
                  buttonFilledFull
                  title="Confirm"
                  style={{
                    color: "white",
                    height: "40px",
                    marginTop: "5px",
                    fontWeight: "400",
                    pointerEvents: disconnectionLoader && "none",
                    backgroundColor: disconnectionLoader && "#E2CDF4",
                  }}
                  // loading={loading}
                  onClick={() => handleDisconnect()}
                />
              </div>
              <div
                style={{ paddingRight: "15px", marginTop: "-26px" }}
                className="integration-menu-buttons"
              >
                <Button
                  buttonHollowLg
                  title="Cancel"
                  style={{
                    height: "40px",
                    color: disconnectionLoader && "#E2CDF4",
                    pointerEvents: disconnectionLoader && "none",
                  }}
                  onClick={() => setIsDisconnectView(false)}
                />
              </div>
            </div>
          )
        )}
      </div>
      {/* DISCONNECT VIEW */}
      {disconnectionLoader ? (
        <div
          className="d-flex align-items-center flex-column"
          style={{ marginTop: "100px", height: "166px" }}
        >
          <div>
            <CircularLoader />
          </div>
          <div className="head6 grey8" style={{ marginTop: "96px" }}>
            Disconnecting ..
          </div>
          <div
            className="s1 grey7"
            style={{ marginTop: "12px", textAlign: "center" }}
          >
            This wont take more than few seconds, please stay connected to
            internet and don’t close the tab
          </div>
        </div>
      ) : disconnectSuccess ? (
        <div
          className="d-flex align-items-center flex-column"
          style={{ marginTop: "100px", height: "166px" }}
        >
          <div>
            <CircularSuccess />
          </div>
          <div className="head6 grey8" style={{ marginTop: "24px" }}>
            Disconnected Successfully!
          </div>
          <div
            className="s1 grey7"
            style={{ marginTop: "12px", textAlign: "center" }}
          >
            Now you can proceed with your platform
          </div>
        </div>
      ) : (
        isDisconnectView && (
          <div>
            <div
              className="head6 grey8"
              style={{ marginTop: "32px", marginBottom: "16px" }}
            >
              Integration will be disconnected and all the relevant data will be
              deleted
            </div>
            <div className="s1 grey8">Are you sure you want to continue?</div>

            {/* border here */}
            <div
              style={{
                borderBottom: "2px solid #DEE2E6",
                width: "100%",
                marginBottom: "24px",
                marginTop: "24px",
              }}
            />

            <div className="head6 grey8" style={{ marginBottom: "16px" }}>
              Details
            </div>

            <div
              style={{ minWidth: "280px", width: "100%", maxWidth: "400px" }}
            >
              <CrewneticsSymbol />
              <ArrowsLeftRight
                style={{ width: "20px", height: "20px", margin: "0 32px" }}
              />
              <img width="44px" height="44px" src={rowValue.image_url} />
              <span className="head6 grey8" style={{ marginLeft: "16px" }}>
                {rowValue.name}
              </span>
            </div>

            <div
              className="s2 grey8"
              style={{ marginTop: "24px", marginBottom: "8px" }}
            >
              Description
            </div>
            <div className="b1 grey7">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
              rhoncus accumsan, et praesent lacus volutpat nulla. Pellentesque
              ipsum cursus vel nisl mauris risus volutpat nibh. Enim scelerisque
              sit egestas facilisis nulla quisque urna id odio.
            </div>
          </div>
        )
      )}

      {!isCurrentRowConnected && !disconnectionLoader && !disconnectSuccess && (
        <>
          <div
            className="head6 grey8"
            style={{ marginTop: "32px", marginBottom: "16px" }}
          >
            Connect {rowValue.name} to your Crewnetics Account
          </div>

          <div style={{ minWidth: "280px", width: "100%", maxWidth: "400px" }}>
            <CrewneticsSymbol />
            <ArrowsLeftRight
              style={{ width: "20px", height: "20px", margin: "0 32px" }}
            />
            <img width="44px" height="44px" src={rowValue.image_url} />
            <span className="head6 grey8" style={{ marginLeft: "16px" }}>
              {rowValue.name}
            </span>
          </div>

          <div
            className="s2 grey8"
            style={{ marginTop: "24px", marginBottom: "8px" }}
          >
            Description
          </div>
          <div className="b1 grey7">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
            rhoncus accumsan, et praesent lacus volutpat nulla. Pellentesque
            ipsum cursus vel nisl mauris risus volutpat nibh. Enim scelerisque
            sit egestas facilisis nulla quisque urna id odio.
          </div>
          <span
            className="s2 grey8"
            style={{ marginTop: "24px", marginBottom: "18px" }}
          >
            Current Status
          </span>

          {Object.prototype.hasOwnProperty.call(
            props.toolsStatus,
            rowValue?.name?.toLowerCase(),
          ) ? (
            <div>
              <Connected style={{ marginRight: "10px" }} />
              <span className="b2 grey8">Connected</span>
            </div>
          ) : (
            <div>
              <Disconnected style={{ marginRight: "10px" }} />
              <span className="b2 grey8">Not Connected</span>
            </div>
          )}
          <div style={{ paddingTop: "32px" }}>
            <div className="head6 grey9" style={{ marginBottom: "24px" }}>
              Features
            </div>
          </div>
          <ul className="b1 grey9" style={{ listStyle: "outside" }}>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          </ul>
        </>
      )}

      {mapUserView && (
        <>
          <span className="head6 grey8" style={{ marginBottom: "16px" }}>
            Map Detected User
          </span>
          <span className="s1 grey8">
            {`You are about to map ${
              IsDetectedUserView || mapUserView
                ? currentRow?.username
                : currentRow?.first_name + " " + currentRow?.last_name
            } from ${rowValue.name}
					to ${currentActiveUser?.first_name} ${
            currentActiveUser?.last_name
          }. Are you sure you want to continue?`}
          </span>
        </>
      )}

      {(isUserInformationView || IsDetectedUserView || mapUserView) && (
        <div>
          {mapUserView && (
            <div
              style={{
                borderBottom: "2px solid #DEE2E6",
                width: "100%",
                marginBottom: "24px",
                marginTop: "24px",
              }}
            />
          )}
          <span className="head6 grey9">
            {IsDetectedUserView && !mapUserView
              ? "Detected User’s Details"
              : "User Details"}
          </span>

          <div
            className={`d-flex align-items-center ${
              mapUserView && "justify-content-between"
            } w-100`}
            style={{ marginTop: "16px" }}
          >
            <div style={{ display: "inline-block", width: "50%" }}>
              <div className="s2 grey8">
                {mapUserView ? "Detected User" : "Name"}
              </div>
              <div style={{ marginTop: "12px" }}>
                <Field
                  className="b1 grey7"
                  inputFieldFullReadOnly
                  noDropDown
                  readOnly
                  value={
                    IsDetectedUserView || mapUserView
                      ? currentRow?.username
                      : currentRow?.first_name + " " + currentRow?.last_name
                  }
                />
              </div>
            </div>

            <div
              className=""
              style={{
                marginLeft: "32px",
                display: "inline-block",
                whiteSpace: "nowrap",
              }}
            >
              <div className="s2 grey8">Platform</div>

              <div style={{ marginTop: "12px" }}>
                <img src={rowValue.image_url} width="30" height="30" />
                <span className="b1 grey7" style={{ marginLeft: "16px" }}>
                  {rowValue.name}
                </span>
              </div>
            </div>

            {mapUserView && (
              <>
                <ArrowsLeftRight
                  style={{
                    minWidth: "24px",
                    minHeight: "24px",
                    margin: "0 32px",
                    marginTop: "10px",
                  }}
                />

                <div
                  className=""
                  style={{
                    marginLeft: "32px",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="s2 grey8">Active User</div>
                  <div style={{ marginTop: "12px" }}>
                    <div
                      style={{
                        display: "flex",
                        margin: 0,
                        alignItems: "center",
                        pointerEvents: "none",
                      }}
                    >
                      <span
                        className="s1"
                        style={{
                          marginRight: "16px",
                          minWidth: "37px",
                          background: "#495057",
                          color: "#F8F9FA",
                          minHeight: "37px",
                          borderRadius: "100px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {currentActiveUser?.first_name.substring(0, 1)}
                      </span>
                      <span className="b1 grey8" style={{ display: "flex" }}>
                        {currentActiveUser?.first_name +
                          " " +
                          currentActiveUser?.last_name}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {(IsDetectedUserView && !mapUserView) ||
            (isUserInformationView && (
              <div
                style={{
                  borderBottom: "2px solid #DEE2E6",
                  width: "100%",
                  marginBottom: "24px",
                  marginTop: "24px",
                }}
              />
            ))}
        </div>
      )}

      {isUserInformationView && (
        <>
          <span className="head6 grey8">Event History</span>

          <div
            className="d-flex justify-content-between"
            style={{ margin: "16px 0" }}
          >
            <span className="s2 grey8">Event</span>
            <span className="s2 grey8">Date</span>
          </div>

          <div className="d-flex justify-content-between">
            <span className="s1 grey8">
              <ul style={{ padding: 0 }}>
                <li style={{ marginBottom: "16px" }}>
                  Logged in into the sytem
                </li>
                <li style={{ marginBottom: "16px" }}>Change password</li>
                <li style={{ marginBottom: "16px" }}>
                  Logged out of the system
                </li>
              </ul>
            </span>
            <span className="s1 grey8">
              <ul>
                <li style={{ marginBottom: "16px" }}>12-23-23</li>
                <li style={{ marginBottom: "16px" }}>12-23-23</li>
                <li style={{ marginBottom: "16px" }}>12-23-23</li>
              </ul>
            </span>
          </div>
        </>
      )}

      {IsDetectedUserView && !mapUserView && (
        <>
          {/* border here */}
          <div
            style={{
              borderBottom: "2px solid #DEE2E6",
              width: "100%",
              marginBottom: "24px",
              marginTop: "24px",
            }}
          />

          <div className="head6 grey9">Active users</div>
          <span className="b1 grey7">
            Map the detected user to any of your active users to help us
            identify their account
          </span>

          <div>
            <Field
              inputFieldFullExpand
              placeholder="Search"
              name="active_users"
              value={usersSearch}
              onChange={(e) => {
                setTablePage(1);
                setUsersSearch(e.target.value);
              }}
              style={{
                margin: "48px 0 20px 0",
                border: "1px solid #E9ECEF",
                borderRadius: "10px",
                background: "#FFFFFF",
              }}
              inputStyle={{
                color: "#6C757D",
                border: "none",
                height: "48px",
                background: "#FFFFFF",
              }}
            />
          </div>

          <Table
            data={data}
            inputValue={usersSearch}
            columns={mapUserColumns}
            setTablePage={setTablePage}
            page={page}
            loading={tableLoader}
            handleTableRowClick={handleActiveUserToMap}
            onMouseOver={(row) => handleIconShow(row.user_id)}
            onMouseLeave={() => setCurrentRowId(null)}
            emptyState="User not found"
            displayAvatar
          />
        </>
      )}

      {!isUserInformationView &&
        !IsDetectedUserView &&
        isCurrentRowConnected &&
        !isDisconnectView && (
          <>
            <div className="tags d-flex" style={{ marginTop: "24px" }}>
              <div
                className={`b1 d-flex justify-content-center ${
                  currentTab === 1 ? "grey8" : "grey6"
                }`}
                style={{
                  borderBottom:
                    currentTab === 1
                      ? "4px solid #7B2CBF"
                      : "4px solid #E9ECEF",
                  padding: "8px",
                  width: "200px",
                  cursor: "pointer",
                }}
                onClick={() => setCurrentTab(1)}
              >
                {"Integration Settings"}
              </div>
              <div
                className={`b1 d-flex justify-content-center ${
                  currentTab === 2 ? "grey8" : "grey6"
                }`}
                style={{
                  // color:
                  borderBottom:
                    currentTab === 2
                      ? "4px solid #7B2CBF"
                      : "4px solid #E9ECEF",
                  padding: "8px",
                  width: "200px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUsersSearch("");
                  setTablePage(1);
                  setCurrentTab(2);
                }}
              >
                {"Connected Users"}
              </div>
              <div
                className={`b1 d-flex justify-content-center ${
                  currentTab === 3 ? "grey8" : "grey6"
                }`}
                style={{
                  // color:
                  borderBottom:
                    currentTab === 3
                      ? "4px solid #7B2CBF"
                      : "4px solid #E9ECEF",
                  padding: "8px",
                  width: "200px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUsersSearch("");
                  setTablePage(1);
                  setCurrentTab(3);
                }}
              >
                {"Detected Users"}
              </div>
            </div>

            {currentTab === 1 ? (
              <>
                <span
                  className="head6 grey9"
                  style={{ paddingTop: "48px", paddingBottom: "24px" }}
                >
                  {/* Connect {rowValue.name} to your Crewnetics Account */}
                  Connected Platform
                </span>

                <div
                  style={{
                    minWidth: "280px",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                >
                  <CrewneticsSymbol />
                  <ArrowsLeftRight
                    style={{ width: "20px", height: "20px", margin: "0 32px" }}
                  />
                  <img width="44px" height="44px" src={rowValue.image_url} />
                  <span className="head6 grey8" style={{ marginLeft: "16px" }}>
                    {rowValue.name}
                  </span>
                </div>

                <span
                  className="s2 grey8"
                  style={{ marginTop: "24px", marginBottom: "8px" }}
                >
                  Description
                </span>
                <span className="b1 grey7">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                  rhoncus accumsan, et praesent lacus volutpat nulla.
                  Pellentesque ipsum cursus vel nisl mauris risus volutpat nibh.
                  Enim scelerisque sit egestas facilisis nulla quisque urna id
                  odio.
                </span>

                <span
                  className="s2 grey8"
                  style={{ marginTop: "24px", marginBottom: "18px" }}
                >
                  Current Status
                </span>

                <div>
                  {Object.prototype.hasOwnProperty.call(
                    props.toolsStatus,
                    rowValue?.name?.toLowerCase(),
                  ) ? (
                    <>
                      <Connected style={{ marginRight: "10px" }} />
                      <span className="b1">Connected</span>
                    </>
                  ) : (
                    <>
                      <Disconnected style={{ marginRight: "10px" }} />
                      <span className="b1">Not Connected</span>
                    </>
                  )}
                </div>
                <div style={{ paddingTop: "32px" }}>
                  <div className="head6 grey9" style={{ marginBottom: "24px" }}>
                    Features
                  </div>
                </div>
                <ul className="b1 grey9" style={{ listStyle: "outside" }}>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                </ul>
              </>
            ) : currentTab === 2 ? (
              <>
                <div>
                  <Field
                    inputFieldFullExpand
                    placeholder="Search"
                    name="connected_users"
                    value={usersSearch}
                    onChange={(e) => {
                      setTablePage(1);
                      setUsersSearch(e.target.value);
                    }}
                    style={{
                      margin: "48px 0 20px 0",
                      border: "1px solid #E9ECEF",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                    }}
                    inputStyle={{
                      color: "#6C757D",
                      border: "none",
                      height: "48px",
                      background: "#FFFFFF",
                    }}
                  />
                </div>
                <Table
                  data={data}
                  inputValue={usersSearch}
                  columns={columns}
                  page={page}
                  setTablePage={setTablePage}
                  handleTableRowClick={handleUserInformation}
                  onMouseOver={(row) => handleIconShow(row.user_id)}
                  onMouseLeave={() => setCurrentRowId(null)}
                  loading={tableLoader}
                  emptyState="User not found"
                  displayAvatar
                />
              </>
            ) : currentTab === 3 ? (
              <>
                <div>
                  <Field
                    inputFieldFullExpand
                    placeholder="Search"
                    name="detected_users"
                    value={usersSearch}
                    onChange={(e) => {
                      setTablePage(1);
                      setUsersSearch(e.target.value);
                    }}
                    style={{
                      margin: "48px 0 20px 0",
                      border: "1px solid #E9ECEF",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                    }}
                    inputStyle={{
                      color: "#6C757D",
                      border: "none",
                      height: "48px",
                      background: "#FFFFFF",
                    }}
                  />
                </div>
                <Table
                  data={data}
                  inputValue={usersSearch}
                  columns={columnsDetectedUsers}
                  page={page}
                  setTablePage={setTablePage}
                  handleTableRowClick={handleDetectedUserClick}
                  onMouseOver={(row) => handleIconShow(row.id)}
                  onMouseLeave={() => setCurrentRowId(null)}
                  loading={tableLoader}
                  emptyState="User not found"
                  displayAvatar
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
    </div>
  );
}
