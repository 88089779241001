import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "../../../components/sub-components/Switch";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import Button from "../../../components/sub-components/Button";
import BackgroundBlur from "../../../components/sub-components/Blur";
import { ReactComponent as CaretLeft } from "../../../assets/icons/CaretLeft.svg";
// import MuiTables from "../../../components/Tables/MuiTables";
import PlanDetails from "../../../components/Cards/PlanDetails";
import PaymentMethod from "../../../components/Cards/PaymentMethod";
import BillingCard from "../../../components/Cards/BillingCard";
import ConfirmPlan from "../../../components/SideMenus/ConfirmPlan";
import BillingsModal from "../../../components/Modals/Billings";
import {
  convertUnixTimeStamp,
  convertUnixTimeStampWithMonthName,
} from "../../../utilities/dateConversion";
import Alerts from "../../../components/Alerts/Alerts";
import { Table } from "../../../components/Tables/Table";
import {
  getCurrentPlan,
  getStripePricings,
  getSubscriptions,
  handleCancelSubscriptionInStore,
  handleChangePlan,
  handleSideMenu,
  listStripeCustomerInvoices,
  setIsChangePlanView,
  setMonthly,
  setTablePageInBillingStore,
  setYearly,
  toggleConfirmPlanSideMenu,
  togglePaymentMethodExpiredSideMenu,
  upgradePlanErrorStateRefresh,
} from "../../../redux/slices/billingSlice";

export default function Billing() {
  const [starter, setStarter] = useState(true);
  const [price, setPrice] = useState(null);
  const [sideMenu, setSideMenu] = useState(false);
  const [isSubscriptionCancelModal, setIsSubscriptionCancelModal] =
    useState(false);
  const [isSubscriptionCancelConfirm, setIsSubscriptionCancelConfirm] =
    useState(false);
  const [applyCouponConfirmPlan, setApplyCouponConfirmPlan] = useState(false);
  const [professional, setProfessional] = useState(false);

  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.billingsReducer.tableData);

  const mediaQuery = useWindowDimensions();

  const subscriptionPackages = useSelector(
    (state) => state.authReducer.subscriptionPackages,
  );

  const couponResult = useSelector((state) => state.authReducer.couponResult);

  const {
    customerInvoices,
    isSideMenu,
    isConfirmPlanSideMenu,
    stripePricings,
    subscription,
    isChangePlanView,
    isPaymentMethodExpired,
    // isPaymentMethodExpiredSideMenu
  } = useSelector((state) => state.billingsReducer);
  const { isMonthly, isYearly, plan } = useSelector(
    (state) => state.billingsReducer.paymentPlans,
  );

  console.log(isMonthly, isYearly);
  const columns = [
    {
      key: "id",
      name: "Transaction ID",
      minWidth: 170,
    },
    {
      key: "_",
      name: "Type",
      minWidth: 170,
      accessor: (row) => (
        <span className="b1">
          {/* THIS NEEDS TO BE FETCH FROM API */}
          Enterprise Subscription
        </span>
      ),
    },
    {
      key: "date_time",
      name: "Date & Time",
      minWidth: 140,
      accessor: (row) => {
        convertUnixTimeStamp(row?.created);
      },
    },
    {
      key: "amount_billed",
      name: "Amount Billed",
      minWidth: 100,
      align: "left",
      accessor: (row) => (
        <span className="b1">
          {row?.lines?.data[0]?.price?.currency === "usd" && "$"}
          {row?.lines?.data[0]?.price?.unit_amount}{" "}
          {row?.lines?.data[0]?.price?.currency.toUpperCase()}
        </span>
      ),
      // format: (value) => value.toLocaleString("en-US"),
    },
  ];

  useEffect(() => {
    dispatch(getSubscriptions());
    dispatch(listStripeCustomerInvoices());
    dispatch(getStripePricings());
  }, [dispatch]);

  useEffect(() => {
    if (customerInvoices?.length > 0) {
      convertUnixTimeStamp();
    }
  }, [customerInvoices]);

  useEffect(() => {
    if (subscription.name !== null && subscription.interval !== null) {
      const data = {
        name: subscription.name,
        isMonthly: subscription.interval === "month",
        isYearly: subscription.interval === "year",
      };
      dispatch(getCurrentPlan(data));
    }
  }, [subscription]);

  useEffect(() => {
    if (isMonthly) {
      if (plan === "Starter") {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[0]?.package_details?.prices?.monthly *
                couponResult?.percentOff) /
              100
            }/mo`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[0]?.package_details?.prices?.monthly -
              couponResult?.amountOff / 100
            }/mo`,
          );
        } else {
          setPrice(
            `$${subscriptionPackages[0]?.package_details?.prices?.monthly}/mo`,
          );
        }
      } else if (plan === "Professional") {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[1]?.package_details?.prices?.monthly *
                couponResult?.percentOff) /
              100
            }/mo`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[1]?.package_details?.prices?.monthly -
              couponResult?.amountOff / 100
            }/mo`,
          );
        } else {
          setPrice(
            `$${subscriptionPackages[1]?.package_details?.prices?.monthly}/mo`,
          );
        }
      }
    } else if (isYearly) {
      if (plan === "Starter") {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[0]?.package_details?.prices?.yearly *
                couponResult?.percentOff) /
              100
            }/year`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[0]?.package_details?.prices?.yearly -
              couponResult?.amountOff / 100
            }/year`,
          );
        } else {
          setPrice(
            `$${subscriptionPackages[0]?.package_details?.prices?.yearly}/year`,
          );
        }
      } else if (plan === "Professional") {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[1]?.package_details?.prices?.yearly *
                couponResult?.percentOff) /
              100
            }/year`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[1]?.package_details?.prices?.yearly -
              couponResult?.amountOff / 100
            }/year`,
          );
        } else {
          setPrice(
            `$${subscriptionPackages[1]?.package_details?.prices?.yearly}/year`,
          );
        }
      }
    }
  }, [
    subscriptionPackages,
    isMonthly,
    isYearly,
    starter,
    professional,
    couponResult,
  ]);

  const getScreenWidth = () => {
    if (mediaQuery.width < "768") {
      return "Tablet";
    }
    if (mediaQuery.width < "576") {
      return "Mobile";
    }
    if (mediaQuery.width < "992") {
      return "Desktop";
    }
    if (mediaQuery.width > "992") {
      return "DesktopFull";
    }
  };

  const handleAddCard = () => {
    setSideMenu(true);
  };

  const handleConfirmPlan = () => {
    dispatch(toggleConfirmPlanSideMenu());
  };

  const handleConfirmPlanSideMenuClose = () => {
    dispatch(toggleConfirmPlanSideMenu());
    // Refresh States
    dispatch(upgradePlanErrorStateRefresh());
    setApplyCouponConfirmPlan(false);
  };

  const handleCancelSubscription = () => {
    setIsSubscriptionCancelModal(true);
    dispatch(handleCancelSubscriptionInStore(subscription.stripePriceId));
  };

  const handleSwitchChange = (value) => {
    console.log("value", value);
    if (value) {
      dispatch(setYearly(true));
      dispatch(setMonthly(false));
    }

    if (!value) {
      dispatch(setMonthly(true));
      dispatch(setYearly(false));
    }
  };

  const setTablePage = (data) => {
    dispatch(setTablePageInBillingStore(data));
  };

  return (
    <div>
      {isPaymentMethodExpired && (
        <Alerts
          billings
          handleExpiredCardSideMenu={() =>
            dispatch(togglePaymentMethodExpiredSideMenu())
          }
        />
      )}

      {isSideMenu && (
        <BackgroundBlur closeMenu={() => dispatch(handleSideMenu())} />
      )}
      <BillingsModal
        subscriptionCancelModal
        isSubscriptionCancelModal={isSubscriptionCancelModal}
        setIsSubscriptionCancelModal={setIsSubscriptionCancelModal}
        isSubscriptionCancelConfirm={isSubscriptionCancelConfirm}
        setIsSubscriptionCancelConfirm={setIsSubscriptionCancelConfirm}
        subscription={subscription}
        convertUnixTimeStamp={convertUnixTimeStampWithMonthName}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 50px",
        }}
      />
      {/* TO CHECK FOR EXPIRY */}
      {/* <Elements stripe={stripePromise}>
				<AddCard 
					handleSideMenuClose={handleSideMenuClose}
					sideMenu={true}
					paymentMethodExpired
				/>
			</Elements> */}
      {/* TO CHECK FOR EXPIRY */}

      <div
        className="page-body-new"
        style={{ display: "block", padding: "44px" }}
      >
        <div className="container-fluid">
          {!isChangePlanView ? (
            <div className="row row-cols-auto d-flex">
              <div className="col-12 col-lg-6">
                <div className="row">
                  <PlanDetails
                    // setIsChangePlan={setIsChangePlan}
                    handleCancelSubscription={handleCancelSubscription}
                    getScreenWidth={getScreenWidth}
                    mediaQuery
                  />

                  {/* ADD CARD */}
                  <PaymentMethod
                    mediaQuery
                    getScreenWidth={getScreenWidth}
                    handleAddCard={handleAddCard}
                  />
                </div>
              </div>

              <div
                className="col-12 col-lg-6"
                style={{ marginTop: mediaQuery.width < "992" && "40px" }}
              >
                <div className="head6 grey8" style={{ marginBottom: "11px" }}>
                  Transaction History
                </div>
                {/* NEEDS SET TABLE PAGE AND PAGE HERE */}
                <Table
                  // convertUnixTimeStamp={convertUnixTimeStamp}
                  // billings
                  data={customerInvoices}
                  columns={columns}
                  inputValue={""}
                  // columns={columns}
                  page={page}
                  setTablePage={setTablePage}
                  // setSideMenuRow={setSideMenuRow}
                  // setMenuToggle={setMenuToggle}
                  // handleTableRowClick={setSideMenuRow}
                  // onMouseOver={(row) => handleIconShow(row.user_id)}
                  // onMouseLeave={() => setCurrentRowId(null)}
                  // displayAvatar
                />
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <ConfirmPlan
                sideMenu={isConfirmPlanSideMenu}
                setIsConfirmPlanSideMenu={isConfirmPlanSideMenu}
                applyCouponConfirmPlan={applyCouponConfirmPlan}
                setApplyCouponConfirmPlan={setApplyCouponConfirmPlan}
              />

              {isConfirmPlanSideMenu && (
                <BackgroundBlur
                  closeMenu={() => handleConfirmPlanSideMenuClose()}
                />
              )}

              <div
                className="d-flex flex-column w-100"
                style={{ textAlign: "center", paddingBottom: "56px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setIsChangePlanView(false));
                      dispatch(getSubscriptions());
                    }}
                  >
                    <CaretLeft style={{ width: "25px", height: "26px" }} />
                    <span
                      className="head5 grey9"
                      style={{ paddingLeft: "16px" }}
                    >
                      Back
                    </span>
                  </div>
                  <div className="head4 grey8" style={{ flex: "1" }}>
                    Payment Plans
                  </div>
                </div>
                <div style={{ paddingTop: "32px" }}>
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className="head6 primary-color"
                      style={{ paddingRight: "12px" }}
                    >
                      Pay Monthly
                    </span>

                    <Switch
                      billing
                      onChange={handleSwitchChange}
                      checked={isYearly}
                    />
                    <div
                      className="head6 grey7"
                      style={{ paddingLeft: "12px" }}
                    >
                      Pay Yearly
                    </div>

                    <div className="caption overline primary-color">
                      / Save 25%
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <BillingCard
                  starter
                  billing
                  className="billing-cards"
                  currentPlan={
                    plan?.split(" ")[0] === "Base" ||
                    plan?.split(" ")[0] === "Starter"
                  }
                  selected={plan === "Base" || plan === "Starter"}
                  price={
                    isMonthly
                      ? stripePricings[0]?.stripe_pricings[0]?.price_per_unit
                      : stripePricings[0]?.stripe_pricings[1]?.price_per_unit
                  }
                  package={stripePricings[0]}
                  time={isMonthly ? "month" : "year"}
                  onClick={() => dispatch(handleChangePlan("Starter"))}
                />
                <BillingCard
                  billing
                  professional
                  className="billing-cards"
                  style={{ margin: "0 48px" }}
                  currentPlan={plan?.split(" ")[0] === "Professional"}
                  selected={plan === "Professional"}
                  price={
                    isMonthly
                      ? stripePricings[1]?.stripe_pricings[0]?.price_per_unit
                      : stripePricings[1]?.stripe_pricings[1]?.price_per_unit
                  }
                  package={stripePricings[1]}
                  time={isMonthly ? "month" : "year"}
                  onClick={() => dispatch(handleChangePlan("Professional"))}
                />
                <BillingCard billing enterprise />
              </div>
              <div
                style={{
                  width: "248px",
                  marginTop: "76px",
                }}
              >
                <Button
                  typography="head6 grey1"
                  buttonFilledFull
                  title="Confirm Plan"
                  onClick={handleConfirmPlan}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
