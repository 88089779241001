import React from "react";
import Button from "../sub-components/Button";
import BpCheckbox from "../sub-components/Checkbox";
import Field from "../sub-components/Field";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import BackgroundBlur from "../../components/sub-components/Blur";

const BottomMenu = (props) => {
  const {
    mobileModal,
    IntegrationFilterActions,
    actionMenuMobile,
    filterColumn,
    integrationsData,
    filterInputValue,
    setFilterInputValue,
    selected,
    handleClick,
    filters,
    clearAllFilters,
    handleActionMenuMobile,
    handleApplyFilterMobile,
    handleRemoveIndividualFilter,
  } = props;

  const filterSubMenu = () => {
    const firstSelectedFilterTest = [];
    const currentFilterColumn = filterColumn.toLowerCase();
    integrationsData.map((a) =>
      firstSelectedFilterTest.push({
        [currentFilterColumn]: a[currentFilterColumn],
      }),
    );
    const sortedList = firstSelectedFilterTest.sort((a, b) =>
      a[currentFilterColumn].localeCompare(b[currentFilterColumn]),
    );
    const uniqueData = sortedList.filter(
      (v, i, a) =>
        a.findIndex(
          (v2) => v2[currentFilterColumn] === v[currentFilterColumn],
        ) === i,
    );

    let prev = "";

    const isSelected = (name) => {
      const ans = selected.findIndex(
        (x) => x[currentFilterColumn] === name[currentFilterColumn],
      );
      return ans !== -1;
    };

    return (
      <div
        style={{ width: "100%", marginTop: "16px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <Field
            inputFieldFullSearchIcon
            placeholder={`Search ${filterColumn}`}
            value={filterInputValue}
            onChange={(e) => setFilterInputValue(e.target.value)}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "52px",
              borderRight: "1px solid #e9ecef",
              borderRadius: "10px",
            }}
          />
        </div>
        <div
          style={{ height: "100vh", width: "100%", paddingBottom: "20px" }}
          className="filter-sub-menu-card"
        >
          {uniqueData.map((o1, i) => {
            const isItemSelected = isSelected(o1);
            const currentSubFilter = o1[currentFilterColumn];
            const isSearch = currentSubFilter
              .toLowerCase()
              .includes(filterInputValue.toLowerCase());
            const filterFirstLetter = currentSubFilter[0];
            if (prev === filterFirstLetter) {
              prev = "";
            } else {
              prev = filterFirstLetter;
            }

            return (
              <div key={i}>
                {prev === filterFirstLetter && (
                  <span
                    className="s2"
                    style={{ display: "flex", margin: "22px 0" }}
                  >
                    {isSearch && prev}
                  </span>
                )}
                {isSearch ? (
                  <div className="d-flex justify-content-between w-100">
                    <span
                      key={i}
                      className="s2"
                      style={{
                        display: "flex",
                        marginLeft: "4px",
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                    >
                      {currentSubFilter}
                    </span>
                    <span
                      onClick={() => {
                        handleClick(o1);
                      }}
                    >
                      <BpCheckbox
                        checked={isItemSelected}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        width={32}
                        height={32}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`bottom-menu-box ${
        mobileModal === true ? "open border" : "bottom-menu-box border"
      }`}
    >
      <div className="d-flex justify-content-between w-100">
        <div className="head6">
          {actionMenuMobile.menu ? (
            <span
              className="head6"
              style={{ fontSize: "20px" }}
              onClick={() => handleActionMenuMobile(actionMenuMobile.click)}
            >
              <Arrow_left
                stroke="#343A40"
                style={{ marginRight: "16px", width: "32px" }}
              />
              {actionMenuMobile.click}
            </span>
          ) : (
            <span className="head6" style={{ fontSize: "20px" }}>
              Filters
            </span>
          )}
        </div>
        <span
          className="s2"
          style={{ color: "#7B2CBF" }}
          onClick={() => clearAllFilters()}
        >
          Reset
        </span>
      </div>
      <div style={{ marginTop: "16px" }}>
        <div style={{ borderBottom: "1px solid #E9ECEF", margin: "0 -25px" }} />
        {IntegrationFilterActions.map((filter, index) => {
          return (
            <div key={index}>
              {!actionMenuMobile.menu && (
                <div>
                  <div
                    className="d-flex justify-content-between w-100"
                    style={{ padding: "16px 0", paddingBottom: "8px" }}
                  >
                    <span className="s2">{filter}</span>

                    <span
                      className="b2"
                      style={{ fontSize: "14px" }}
                      onClick={() => handleActionMenuMobile(filter)}
                    >
                      Edit
                    </span>
                  </div>
                  {Object.prototype.hasOwnProperty.call(
                    filters,
                    filter.toLowerCase(),
                  ) && (
                    <span
                      className="d-flex align-items-center s2"
                      style={{ paddingBottom: "16px", color: "#7B2CBF" }}
                    >
                      {Object.values(filters[filter?.toLowerCase()]).length ===
                      1
                        ? Object.values(filters[filter?.toLowerCase()])[0]
                        : Object.values(filters[filter?.toLowerCase()]).length >
                            1
                          ? `${
                              Object.values(filters[filter?.toLowerCase()])[0]
                            } and
												${Object.keys(filters[filter?.toLowerCase()]).length - 1} more`
                          : ""}

                      <Close
                        fill="#7B2CBF"
                        stroke="#7B2CBF"
                        stroke-width="3"
                        style={{
                          marginLeft: "12px",
                          width: "16px",
                          height: "16px",
                        }}
                        onClick={(e) => handleRemoveIndividualFilter(e, filter)}
                      />
                    </span>
                  )}
                  <div
                    style={{
                      borderBottom: "1px solid #E9ECEF",
                      margin: "0 -25px",
                    }}
                  />
                </div>
              )}
              {actionMenuMobile.menu &&
                actionMenuMobile.click === filter &&
                filterSubMenu()}
            </div>
          );
        })}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translate(-50%)",
          width: "100%",
          borderTop: "1px solid #E9ECEF",
        }}
      >
        <div
          style={{
            height: "10vh",
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            buttonFilledFull
            title="Show Results"
            style={{
              color: "white",
              height: "48px",
              marginTop: "5px",
              width: "70vw",
            }}
            onClick={() =>
              handleApplyFilterMobile(
                selected,
                filterColumn.toLowerCase(),
                "MobileView",
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BottomMenu;
