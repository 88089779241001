import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Field from "../../../../components/sub-components/Field";
import CustomSelect from "../../../../components/sub-components/Select";
import { getUsersList } from "../../../../redux/slices/usersSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const SurveyForm = ({
  currentRowToEdit,
  isEditableState,
  selectedTab,
  surveyQuestion,
  setSurveyQuestion,
  selectedSurveyType,
  setSelectedSurveyType,
  selectedRecurrence,
  setSelectedRecurrence,
  isConfirmationModal,
  isRowClicked,
  selectedItem,
  setSelectedItem,
  selectedDate,
  setSelectedDate,
}) => {
  const { activeUsers } = useSelector((state) => state.usersReducer);

  const dispatch = useDispatch();

  const [currentRow, setCurrentRow] = useState({});
  const [isSurveyQuestion, setIsSurveyQuestion] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleSurveyQuestion = (e) => {
    setIsSurveyQuestion(false);
    setSurveyQuestion(e.target.value);
    if (e.target.value === "" && !isRowClicked) {
      return;
    }
  };

  const handleChangeSurveyType = (event) => {
    const value = event.target.value;
    setSelectedSurveyType(value);
  };

  const handleChangeRecurrence = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRecurrence(value);
  };

  const handleDateChange = (name, date) => {
    setSelectedDate((prev) => ({ ...prev, [name]: date.toISOString() }));
  };

  const SelectionComponent = () => {
    const commonStyle = {
      height: "48px",
      borderRadius: "10px",
      padding: "0 12px",
      border: "1px solid #DEDEDE",
      width: "123px",
      cursor: "pointer",
    };

    const selectedStyle = {
      ...commonStyle,
      backgroundColor: "#9300FF",
      color: "white",
    };

    return (
      <div className="d-flex">
        <div
          className="d-flex justify-content-center align-items-center mr-3 head6-semi-bold"
          style={selectedItem === "text" ? selectedStyle : commonStyle}
          onClick={() => setSelectedItem("text")}
        >
          <span>Text</span>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={selectedItem === "emoji" ? selectedStyle : commonStyle}
          onClick={() => setSelectedItem("emoji")}
        >
          <span>😀</span>
        </div>
      </div>
    );
  };

  // THIS CALLING MULTIPLE USER PROFILES WITH THEIR ID'S?
  useEffect(() => {
    if (activeUsers.length === 0) {
      dispatch(getUsersList());
    }
  }, []);

  return (
    <div>
      <div className="head6 grey8" style={{ margin: "24px 0 16px 0" }}>
        {/* {isRowClicked ? "Confirm Changes" : "Confirm department creation"} */}
      </div>

      {selectedTab.id === 1 && (
        <div className={isRowClicked && !isEditableState && "disableEvents"}>
          <div style={{ flexWrap: "nowrap", paddingTop: "10px" }}>
            <div>
              <div>
                <div className="head7-medium grey8">Survey Question</div>
              </div>
              <div
                className={`${
                  !(isEditableState || !isRowClicked) && "disableField"
                }`}
                style={{ paddingBottom: "32px", paddingTop: "12px" }}
              >
                <div>
                  <Field
                    className="b1 grey7"
                    inputFieldFull
                    onChange={handleSurveyQuestion}
                    value={isSurveyQuestion ? currentRow.name : surveyQuestion}
                    style={{
                      background:
                        (isRowClicked && !isEditableState) ||
                        isConfirmationModal
                          ? "#F8F9FA"
                          : "#FFFFFF",
                    }}
                  />
                </div>
              </div>
              <div style={{ paddingBottom: "32px" }} className="row px-0">
                <div className="col-12">
                  <div
                    className="head7-medium grey8"
                    style={{ marginBottom: "12px" }}
                  >
                    Survey Type
                  </div>
                  <CustomSelect
                    items={["Performance", "Well Being", "Happiness"].map(
                      (survey) => {
                        return {
                          value: survey, // Ensure this matches the expected value format
                          label: `${survey}`,
                        };
                      },
                    )}
                    disabled={
                      (isRowClicked && !isEditableState) || isConfirmationModal
                    }
                    value={selectedSurveyType?.value ?? null}
                    onChange={handleChangeSurveyType}
                    error={error}
                    helperText={helperText}
                  />
                </div>

                <div
                  className="col-lg-6 mt-4"
                  // style={{ paddingBottom: "32px" }}
                >
                  <div
                    // ${getInputFieldHeadingTextColor()}
                    className={`body1-medium`}
                    style={{ marginBottom: "12px" }}
                  >
                    Start Date
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="start_date"
                      maxDate={""}
                      value={
                        selectedDate.start_date === null
                          ? null
                          : dayjs(selectedDate.start_date)
                      }
                      onChange={(date) => handleDateChange("start_date", date)}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "28px",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DEDEDE",
                          "&:hover": {
                            borderColor: "#b1b1b1",
                          },
                          "&.Mui-focused": {
                            borderColor: "#b1b1b1",
                          },
                        },
                        width: "100%",
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-lg-6 mt-4">
                  <div
                    className={`body1-medium`}
                    style={{ marginBottom: "12px" }}
                  >
                    End Date
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="end_date"
                      value={
                        selectedDate.end_date === null
                          ? null
                          : dayjs(selectedDate.end_date)
                      }
                      minDate={""}
                      onChange={(date) => handleDateChange("end_date", date)}
                      // disabled={showConfirmationModal || !isEditAllowed}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "28px",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DEDEDE",
                          "&:hover": {
                            borderColor: "#b1b1b1",
                          },
                          "&.Mui-focused": {
                            borderColor: "#b1b1b1",
                          },
                        },
                        width: "100%",
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <div style={{ paddingBottom: "32px" }} className="row px-0">
              <div className="col-12">
                <div
                  className="head7-medium grey8"
                  style={{ marginBottom: "12px" }}
                >
                  Recurrence
                </div>
                <CustomSelect
                  items={["Daily", "Weekly", "Monthly"].map((recurrence) => {
                    return {
                      value: recurrence, // Ensure this matches the expected value format
                      label: recurrence,
                    };
                  })}
                  disabled={
                    (isRowClicked && !isEditableState) || isConfirmationModal
                  }
                  value={selectedRecurrence?.value ?? null}
                  onChange={handleChangeRecurrence}
                  error={error}
                  helperText={helperText}
                />
              </div>
            </div>

            <div className="row px-0">
              <div className="col-12">
                <div
                  className="head7-medium grey8"
                  style={{ marginBottom: "12px" }}
                >
                  Response Type
                </div>
                <SelectionComponent
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyForm;
