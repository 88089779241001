export const setCookie = function (cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = function (cname) {
  const ca = document.cookie.split(";");

  const token = ca.find((c) => {
    return c.replace(" ", "").split("=")[0] === cname;
  });

  if (!token) {
    return "";
  }

  return token.split("=")[1];
};

export const deleteCookie = function (cname) {
  // const d = new Date();
  // d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + "Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = `${cname}=${""};${expires};path=/`;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const capitalizeFirstAndRemoveLastTwoLetters = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1, -2);
};

export const getGraphGranularityMappings = (selectedGranularity) => {
  console.log("selectedGranularity>>", selectedGranularity);
  switch (selectedGranularity) {
    case "users":
      return selectedGranularity.slice(0, -1);

    case "teams":
      return selectedGranularity.slice(0, -1);

    case "cohorts":
      return selectedGranularity.slice(0, -1);
    case "daily":
      return "day";
  }
  return selectedGranularity.slice(0, -2);
  //  if(selectedGranularity === "daily"){
  //   return "day"
  //  }
};

export const detectDateFormat = (data, communicationGraph) => {
  if (!data || data?.length == 0) {
    // No data to analyze
    return null;
  }
  // console.log("vdata", data[0]);

  const firstDate = data[0]?.data[0]?.x; // Assuming 'y' is the key holding the date string
  // const firstDate = communicationGraph
  //   ? data[0][0].data[0].x
  //   : data[0].data[0].x; // Assuming 'y' is the key holding the date string

  if (/^\d{4}-\d{2}-\d{2}$/.test(firstDate)) {
    return "YYYY-MM-DD"; // Date format without time
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(firstDate)) {
    return "YYYY-MM-DDTHH:mm:ss"; // Date format with time
  } else {
    // Unsupported format or invalid date
    return null;
  }
};

export const getFormatForCurrentGranularity = (granularity) => {
  // console.log("granularitygranularity", granularity);
  if (granularity === "second") {
    return ":%S";
  }
  if (granularity === "minute") {
    return "%I:%M";
  }
  if (granularity === "hour") {
    return "%I %p";
  }
  if (granularity === "day") {
    return "%a %d";
  }
  if (granularity === "week") {
    return "%b %d";
  }
  if (granularity === "month") {
    return "%B";
  }
  if (granularity === "year") {
    return "%Y";
  }
};

export const getTickValueIntervals = (granularity) => {
  // if (granularity === "second") {
  //   return ":%S";
  // }
  // if (granularity === "minute") {
  //   return "%I:%M";
  // }
  if (granularity === "hour") {
    // return "every 10 hours";
    return "every hour";
  }
  if (granularity === "day") {
    return "every day";
  }
  if (granularity === "week") {
    return "every week";
  }
  if (granularity === "month") {
    return "every month";
  }
  if (granularity === "year") {
    return "every 1 year";
  }
};

export const emptyRows = (page, rowsPerPage, data) =>
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

export const toggleCheckboxSelection = (
  selectedItems,
  itemToToggle,
  setFunction,
  identifier,
) => {
  const findFunction = (x) => {
    if (
      identifier &&
      Object.prototype.hasOwnProperty.call(x, identifier) &&
      Object.prototype.hasOwnProperty.call(itemToToggle, identifier)
    ) {
      return x[identifier] === itemToToggle[identifier];
    } else {
      return x === itemToToggle;
    }
  };

  const selectedIndex = selectedItems.findIndex(findFunction);

  let newSelected = [];

  if (selectedIndex === -1) {
    // Item not currently selected, add it to the selection
    newSelected = [...selectedItems, itemToToggle];
  } else {
    // Item currently selected, remove it from the selection
    newSelected = [
      ...selectedItems.slice(0, selectedIndex),
      ...selectedItems.slice(selectedIndex + 1),
    ];
  }

  setFunction(newSelected);
};

export const getSurveyGradeBackgroundColors = (grade) => {
  if (grade === "A") {
    return "#00E13218";
  }
  if (grade === "B") {
    return "#117AF418";
  }
  if (grade === "C") {
    return "#EB2D1E18";
  }
};

export const getSurveyGradeTextColors = (grade) => {
  if (grade === "A") {
    return "#00E132";
  }
  if (grade === "B") {
    return "#117AF4";
  }
  if (grade === "C") {
    return "#EB2D1E";
  }
};

export const compareIds = (item1, item2) => {
  const id1 = item1.user_id ?? item1.id;
  const id2 = item2.user_id ?? item2.id;
  return id1 === id2;
};
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getExcludedPathsForHeader = () => {
  const excludedPaths = [
    "/",
    "/login",
    "/signup",
    "/forgot-password",
    "/activation",
    "/activation/:id",
    "/confirm-plan",
    "/join-organization",
  ];
  return excludedPaths;
};
