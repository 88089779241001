import React, { useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  createNewDepartment,
  editDepartment,
  setSideMenuTablePage,
  toggleDepartmentsSideMenu,
} from "../../../../../redux/slices/departmentSlice";
import DepartmentForm from "./DepartmentForm";
import TeamMembers from "./TeamMembers";
import Tabv2 from "../../../../../components/sub-components/Tabv2";
import ActionButtons from "./ActionButtons";
import { ReactComponent as Arrow_left } from "../../../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as Close } from "../../../../../assets/icons/X.svg";

export default function DepartmentsMenu(props) {
  const { setCurrentRowToEdit } = props;
  const { isSideMenuOpen } = useSelector(
    (state) => state.departmentsReducer.sideMenu,
  );
  const { loading } = useSelector((state) => state.departmentsReducer);
  const user = useSelector((state) => state.authReducer.user);
  const { data: teamsData } = useSelector(
    (state) => state.teamsReducer.tableData,
  );
  const [addedTeams, setAddedTeams] = useState([]); // teams which have been added
  const [selectedTab, setSelectedTab] = useState({ id: 1 });
  const [isEditableState, setIsEditableState] = useState(false);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const tabsInitialState = [
    { id: 1, title: "Details" },
    { id: 2, title: "Teams" },
  ];
  const [isConfirmationModal, setIsConfirmationModal] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [selectedHOD, setSelectedHOD] = useState(null);
  const [selectedSubDepartments, setSelectedSubDepartments] = useState([]);
  const [departmentEmail, setDepartmentEmail] = useState("");

  const dispatch = useDispatch();

  const refreshStates = () => {
    setTeamName("");
    setSelectedHOD({});
    setDepartmentEmail("");
    setCurrentRowToEdit({});
    setIsRowClicked(false);
    setIsConfirmationModal(false);
    setAddedTeams([]);
    setIsEditableState(false);
    setSelectedSubDepartments([]);
    setSelectedTab({ id: 1 });
  };

  const handleSideMenuClose = () => {
    refreshStates();
    dispatch(toggleDepartmentsSideMenu());
  };

  const handleCreateNewDepartement = async () => {
    const departmentData = [
      {
        name: teamName,
        tenant_id: user.tenantId,
        hod_id: selectedHOD.value,
        department_email: departmentEmail?.length ? departmentEmail : null,
        team_departments:
          addedTeams.map((team) => ({ team_id: team.id })) ?? null,
      },
      {
        sub_departments_array: selectedSubDepartments,
      },
    ];

    try {
      dispatch(
        isEditableState
          ? editDepartment({
              data: departmentData,
              departementID: props.currentRowToEdit.id,
            })
          : createNewDepartment(departmentData),
      );
      // refreshStates();
    } catch (error) {
      console.error("Failed to create department:", error);
    }
  };

  useEffect(async () => {
    if (Object.keys(props.currentRowToEdit).length > 0) {
      setTeamName(props.currentRowToEdit.name);
      setIsRowClicked(true);
      setDepartmentEmail(props.currentRowToEdit.department_email);
      setSelectedHOD(props.currentRowToEdit?.hod_profile?.user_id);

      const idsOfPreAddedTeams = props.currentRowToEdit.teams.map(
        (team) => team.id,
      );
      const preAddedTeams = teamsData.filter((team) =>
        idsOfPreAddedTeams.includes(team.id),
      );
      setAddedTeams(preAddedTeams);
      setSelectedSubDepartments(
        props.currentRowToEdit.nested_departments.map(
          (department) => department.id,
        ),
      );
    } else {
      setIsRowClicked(false);
      setSelectedSubDepartments([]);
      setSelectedHOD(null);
    }
  }, [props.currentRowToEdit]);

  useEffect(() => {
    if (!isSideMenuOpen) {
      refreshStates();
    }
  }, [isSideMenuOpen]);

  return (
    <Drawer
      anchor="right"
      open={isSideMenuOpen}
      onClose={handleSideMenuClose}
      classes={{ paper: "main-drawer" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <span className="head4 grey8" style={{ fontWeight: "400" }}>
              {isConfirmationModal ? (
                <Arrow_left
                  stroke="#343A40"
                  style={{
                    margin: "2px 20px 5px 0",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsConfirmationModal(false)}
                />
              ) : (
                <Close
                  stroke="#343A40"
                  strokeWidth="2"
                  style={{
                    margin: "0px 20px 3px -16px",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleSideMenuClose}
                />
              )}
              {isRowClicked
                ? props.currentRowToEdit.name
                : "Create new Department"}
            </span>
            <div
              className="d-flex align-items-center flex-row-reverse"
              style={{ width: "fit-content" }}
            >
              <ActionButtons
                teamName={teamName}
                selectedHOD={selectedHOD}
                addedTeams={addedTeams}
                handleSideMenuClose={handleSideMenuClose}
                loading={loading}
                isRowClicked={isRowClicked}
                isEditableState={isEditableState}
                deleteDepartment={props.deleteDepartment}
                setIsEditableState={setIsEditableState}
                isConfirmationModal={isConfirmationModal}
                setIsConfirmationModal={setIsConfirmationModal}
                handleCreateNewDepartement={handleCreateNewDepartement}
                setSelectedTab={setSelectedTab}
              />
            </div>
          </div>
          {(isEditableState || isRowClicked) && !isConfirmationModal && (
            <div>
              <Tabv2
                tabs={tabsInitialState}
                selectedTab={selectedTab}
                onTabChange={(index) => {
                  setSelectedTab(tabsInitialState[index]);
                  dispatch(setSideMenuTablePage(1));
                }}
              />
            </div>
          )}
          <DepartmentForm
            {...props}
            handleSideMenuClose={handleSideMenuClose}
            isEditableState={isEditableState}
            selectedTab={selectedTab}
            teamName={teamName}
            setTeamName={setTeamName}
            selectedHOD={selectedHOD}
            setSelectedHOD={setSelectedHOD}
            selectedSubDepartments={selectedSubDepartments}
            setSelectedSubDepartments={setSelectedSubDepartments}
            departmentEmail={departmentEmail}
            setDepartmentEmail={setDepartmentEmail}
            isConfirmationModal={isConfirmationModal}
            isRowClicked={isRowClicked}
          />
          <TeamMembers
            addedTeams={addedTeams}
            setAddedTeams={setAddedTeams}
            selectedTab={selectedTab}
            isConfirmationModal={isConfirmationModal}
            currentRowToEdit={props.currentRowToEdit}
            isRowClicked={isRowClicked}
            setIsRowClicked={setIsRowClicked}
            isEditableState={isEditableState}
          />
        </div>
      </div>
    </Drawer>
  );
}
