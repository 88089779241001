import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { secure_instance } from "../../axios/axios-config";

// Define initial state using a function to ensure fresh dates on reloads
const getInitialDateRange = () => {
  const START_DATE = dayjs()
    .subtract(1, "week")
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const END_DATE = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS");
  return {
    startDate: START_DATE,
    endDate: END_DATE,
  };
};

const initialState = {
  loading: false,
  globalFilter: "",
  formData: {},
  graphData: [],
  globalFilterIDs: [],
  eventFilterID: [],
  dashboardGlobalDateRange: getInitialDateRange(),
  isTriggerRefetch: false,
  isTriggerProductivityGraphRefetch: false,
  dashboardGlobalFilters: [
    {
      col: "event_time",
      operator: "between",
      value: [
        dayjs(getInitialDateRange().startDate).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(getInitialDateRange().endDate).format("YYYY-MM-DD HH:mm:ss"),
      ],
    },
  ],
  organizationGlobalFilter: {
    model: "GroupMembers",
    filters: [],
  },
};

// export const getGraphDataWithChMaestro = createAsyncThunk(
//   "dashboard/getGraphData",
//   async (query, { rejectWithValue }) => {
//     try {
//       const response = await secure_instance.request({
//         url: "v1/graph/multiline",
//         method: "Post",
//         data: query,
//       });
//       return response.data.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   },
// );

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardDateRange(state, action) {
      state.dashboardGlobalDateRange = action.payload;
    },
    triggerReFetch(state, action) {
      state.isTriggerRefetch = action.payload;
    },
    triggerProductivityGraphReFetch(state, action) {
      state.isTriggerProductivityGraphRefetch = action.payload;
    },
    updateDateFilter(state, action) {
      state.dashboardGlobalFilters = action.payload;
    },
    updateOrganizationFilter(state, action) {
      state.organizationGlobalFilter = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getGraphDataWithChMaestro.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(getGraphDataWithChMaestro.fulfilled, (state, action) => {
  //       state.graphData = action.payload;
  //       state.loading = false;
  //     })
  //     .addCase(getGraphDataWithChMaestro.rejected, (state, action) => {
  //       state.loading = false;
  //       console.error("Failed to fetch graph data:", action.payload);
  //     });
  // },
});

export const {
  setDashboardDateRange,
  triggerReFetch,
  triggerProductivityGraphReFetch,
  updateDateFilter,
  updateOrganizationFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
