import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Field from "../../../../../components/sub-components/Field";
import CustomSelect from "../../../../../components/sub-components/Select";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/Plus.svg";
import { ReactComponent as MinusIcon } from "../../../../../assets/icons/Minus.svg";
import { getUsersList } from "../../../../../redux/slices/usersSlice";

const DepartmentForm = ({
  currentRowToEdit,
  isEditableState,
  selectedTab,
  teamName,
  setTeamName,
  selectedHOD,
  setSelectedHOD,
  selectedSubDepartments,
  setSelectedSubDepartments,
  departmentEmail,
  setDepartmentEmail,
  isConfirmationModal,
  isRowClicked,
}) => {
  const { activeUsers } = useSelector((state) => state.usersReducer);
  const { departmentsData } = useSelector((state) => state.departmentsReducer);

  const dispatch = useDispatch();

  const [currentRow, setCurrentRow] = useState({});
  const [isTeamName, setIsTeamName] = useState(false);
  const [isEmailActive, setIsEmailActive] = useState(false);
  const [showSubDepartmentsField, setShowSubDepartmentsField] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleTeamName = (e) => {
    setIsTeamName(false);
    setTeamName(e.target.value);
    if (e.target.value === "" && !isRowClicked) {
      return;
    }
  };

  const handleDepartmentEmail = (e) => {
    setIsEmailActive(true);
    setDepartmentEmail(e.target.value);
  };

  const handleChangeHOD = (event) => {
    const value = event.target.value;
    setSelectedHOD(value);
  };

  const handleChangeSubDepartment = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubDepartments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // THIS CALLING MULTIPLE USER PROFILES WITH THEIR ID'S?
  useEffect(() => {
    if (activeUsers.length === 0) {
      dispatch(getUsersList());
    }
  }, []);

  useEffect(async () => {
    if (Object.keys(currentRowToEdit).length > 0) {
      if (currentRowToEdit.nested_departments.length > 0) {
        setShowSubDepartmentsField(true);
      }
    }
  }, [currentRowToEdit]);

  return (
    <div>
      <div className="head6 grey8" style={{ margin: "24px 0 16px 0" }}>
        {isRowClicked ? "Confirm Changes" : "Confirm department creation"}
      </div>

      {/* <div
        className="s1 grey8"
        style={{
          marginBottom:
            (newAddedTeamsInDepartment.length === 0 ||
              newDeletedTeamsInDepartment.length === 0) &&
            "24px",
        }}
      >
        {isRowClicked
          ? `You are about to add and remove users from ${teamName}. Are you sure you want to continue?`
          : "Are you sure you want to continue?"}
      </div> */}

      {selectedTab.id === 1 && (
        <div className={isRowClicked && !isEditableState && "disableEvents"}>
          <div style={{ flexWrap: "nowrap", paddingTop: "10px" }}>
            <div>
              <div>
                <div className="head7-medium grey8">Title</div>
              </div>
              <div
                className={`${
                  !(isEditableState || !isRowClicked) && "disableField"
                }`}
                style={{ paddingBottom: "32px", paddingTop: "12px" }}
              >
                <div>
                  <Field
                    className="b1 grey7"
                    inputFieldFull
                    onChange={handleTeamName}
                    value={isTeamName ? currentRow.name : teamName}
                    style={{
                      background:
                        (isRowClicked && !isEditableState) ||
                        isConfirmationModal
                          ? "#F8F9FA"
                          : "#FFFFFF",
                    }}
                  />
                </div>
              </div>
              <div style={{ paddingBottom: "32px" }} className="row px-0">
                <div className="col-6">
                  <div
                    className="head7-medium grey8"
                    style={{ marginBottom: "12px" }}
                  >
                    Head of Department
                  </div>
                  <CustomSelect
                    items={activeUsers.map((user) => {
                      return {
                        value: user.user_id, // Ensure this matches the expected value format
                        label: `${user.first_name} ${user.last_name}`,
                      };
                    })}
                    disabled={
                      (isRowClicked && !isEditableState) || isConfirmationModal
                    }
                    value={selectedHOD?.value ?? null}
                    onChange={handleChangeHOD}
                    error={error}
                    helperText={helperText}
                  />
                </div>

                <div className="col-6">
                  <div className="head7-medium grey8">Email</div>
                  <div
                    style={{
                      paddingTop: "12px",
                    }}
                  >
                    <Field
                      className="b1 grey8"
                      inputFieldFull
                      value={
                        isRowClicked && !isEmailActive
                          ? currentRowToEdit.department_email
                          : departmentEmail
                      }
                      onChange={handleDepartmentEmail}
                      style={{
                        background:
                          (isRowClicked && !isEditableState) ||
                          isConfirmationModal
                            ? "#F8F9FA"
                            : "#FFFFFF",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                maxHeight: showSubDepartmentsField ? "200px" : "0px",
                overflow: "hidden",
                transition: "max-height 0.5s ease",
              }}
            >
              <div className="row px-0">
                <div className="col-6">
                  <div
                    className="head7-medium grey8"
                    style={{ marginBottom: "12px" }}
                  >
                    Sub Department
                  </div>
                  <CustomSelect
                    items={departmentsData
                      .filter((row) => currentRowToEdit?.id !== row.id)
                      .map((department) => {
                        return {
                          value: department.id,
                          label: department.name,
                        };
                      })}
                    value={selectedSubDepartments}
                    onChange={handleChangeSubDepartment}
                    error={error}
                    multiple
                    helperText={helperText}
                    disabled={
                      (isRowClicked && !isEditableState) || isConfirmationModal
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center"
              style={{
                cursor: "pointer",
                width: "192px",
                marginTop: "0px",
              }}
              onClick={() =>
                setShowSubDepartmentsField(
                  showSubDepartmentsField ? false : true,
                )
              }
            >
              {!showSubDepartmentsField ? (
                <PlusIcon
                  fill={
                    !(isRowClicked && !isEditableState) ? "#7B2CBF" : "#ADB5BD"
                  }
                  stroke={
                    !(isRowClicked && !isEditableState) ? "#7B2CBF" : "#ADB5BD"
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <MinusIcon
                  stroke={"#6E00BF"}
                  style={{ width: "18px", height: "32px" }}
                />
              )}
              <span
                className="body2-medium ml-2"
                style={{
                  color: !(
                    (isRowClicked && !isEditableState) ||
                    isConfirmationModal
                  )
                    ? "#6E00BF"
                    : "#ADB5BD",
                }}
              >
                Sub Department
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DepartmentForm;
