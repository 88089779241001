import React from "react";
import Sidebar from "../components/SideBar/Sidebar";

function SidebarLayout(props) {
  return (
    <div className="flex">
      <>
        <div style={{ display: "flex" }}>
          <Sidebar history={props.history} />
          <div
            className="flex"
            style={{
              flex: 1,
              overflowX: "auto",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            {props.children}
          </div>
        </div>
      </>
    </div>
  );
}

export default SidebarLayout;
