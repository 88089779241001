/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import frame from "../../assets/icons/Frame 15.svg";
import { ReactComponent as CrewneticsSymbol } from "../../assets/icons/Logo/crewnetics-logo.svg";
import { ReactComponent as ArrowsLeftRight } from "../../assets/icons/ArrowsLeftRight.svg";
import { ReactComponent as Connected } from "../../assets/icons/Connected.svg";
import { ReactComponent as Disconnected } from "../../assets/icons/Disconnected.svg";
import { ReactComponent as Google } from "../../assets/icons/Google.svg";
import { ReactComponent as Azure } from "../../assets/icons/Microsoft.svg";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";

export default function Integrate(props) {
  const { tool } = props.data;
  const { handleConnect, sideMenu } = props;
  const [loading, setLoading] = useState(false);

  const handleChangeConnect = (data) => {
    setLoading(true);
    handleConnect(data);
  };

  return (
    <div
      className={`side-menu-box ${
        sideMenu === true ? "open border" : "side-menu-box border"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <span className="head5">
          <Arrow_left
            stroke="#343A40"
            style={{
              margin: "2px 10px 5px 0",
              width: "24px",
              cursor: "pointer",
            }}
            onClick={() => props.handleSideMenuClose()}
          />
          <span className="head5 grey9" style={{ paddingLeft: "15.62px" }}>
            Connect your identity provider
          </span>
        </span>

        <div
          className="d-flex align-items-center flex-row-reverse"
          style={{ width: "fit-content" }}
        >
          <div className="integration-menu-buttons">
            <Button
              className="b0-medium grey1"
              buttonFilledFull
              title="Connect"
              style={{
                height: "48px",
                padding: "8px 20px",
                borderRadius: "10px",
              }}
              loading={loading}
              onClick={() => handleChangeConnect(props.data)}
            />
          </div>
          <div
            style={{ paddingRight: "15px" }}
            className="integration-menu-buttons s1 grey6"
          >
            <Button
              buttonHollowLg
              title="Cancel"
              style={{ height: "40px" }}
              onClick={() => props.handleSideMenuClose()}
            />
          </div>
        </div>
      </div>
      {/* <div className="top-border" /> */}

      <span className="head6 grey8" style={{ paddingTop: "30px" }}>
        Connect {tool} to your Crewnetics Account
      </span>

      <div
        className=""
        style={{
          minWidth: "480px",
          width: "100%",
          maxWidth: "600px",
          marginTop: "35px",
        }}
      >
        <CrewneticsSymbol />
        <ArrowsLeftRight
          style={{ width: "20px", height: "20px", margin: "0 32px" }}
        />
        {tool === "GSuite" ? <Google /> : <Azure />}
        <span className="head6 grey8" style={{ marginLeft: "16px" }}>
          {tool}
        </span>
      </div>

      <span
        className="s2 grey8"
        style={{ marginTop: "24px", marginBottom: "8px" }}
      >
        Description
      </span>
      <span className="b1 grey7">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis rhoncus
        accumsan, et praesent lacus volutpat nulla. Pellentesque ipsum cursus
        vel nisl mauris risus volutpat nibh. Enim scelerisque sit egestas
        facilisis nulla quisque urna id odio.
      </span>

      <div style={{ paddingTop: "32px" }}>
        <div style={{ paddingTop: "32px" }}>
          <div className="head6 grey9" style={{ marginBottom: "24px" }}>
            Features
          </div>
        </div>
        <ul className="b1 grey9" style={{ listStyle: "outside" }}>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        </ul>
      </div>
      <div
        className="integration-menu-buttons-container-mobile"
        // style={{ width: "fit-content" }}
      >
        <div
          className="integration-menu-buttons-mobile"
          style={{ width: "100%" }}
        >
          <Button
            buttonFilledFull
            title="Connect"
            style={{
              width: "100%",
              color: "white",
              height: "40px",
              fontWeight: "400",
            }}
            loading={loading}
            onClick={() => handleChangeConnect(props.data)}
          />
        </div>
        <div
          style={{ width: "100%" }}
          className="integration-menu-buttons-mobile"
        >
          <Button
            buttonHollowLg
            title="Cancel"
            style={{ width: "100%", height: "40px", marginTop: "15px" }}
            onClick={() => props.handleSideMenuClose()}
          />
        </div>
      </div>
    </div>
  );
}
