import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const CustomSelect = ({
  items,
  value,
  onChange,
  label,
  styleProps,
  error,
  helperText,
  disabled,
  multiple,
  menuMaxHeight,
  displayEmpty,
}) => {
  return (
    <FormControl variant="outlined" fullWidth style={styleProps} error={error}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        displayEmpty={displayEmpty}
        value={value}
        onChange={onChange}
        label={label}
        multiple={multiple}
        disabled={disabled}
        // SelectDisplayProps={{
        //   MenuProps: {
        //     sx: { height: "300px" },
        //   },
        // }}
        renderValue={(selected) => {
          if (multiple) {
            if (selected?.length === 0) {
              return <em>Search</em>;
            }
            return selected
              .map((selectedValue) => {
                const selectedItem = items.find(
                  (item) => item.value === selectedValue,
                );
                return selectedItem ? selectedItem.label : selectedValue;
              })
              .join(", ");
          }
          const selectedItem = items.find((item) => item.value === selected);
          return selectedItem ? selectedItem.label : <em>Search</em>;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: menuMaxHeight || null,
            },
          },
        }}
        sx={{
          borderRadius: "10px",
          height: "48px",
          backgroundColor: disabled && "#F8F9FA",
          ".root": {
            maxHeight: "40px",
          },
          "&.MuiMenu-paper": {
            height: "40px",
          },
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#DEDEDE",
            },
            "&:hover fieldset": {
              borderColor: "#b1b1b1",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#b1b1b1",
            },
            "&MuiList-root": {
              height: "40px",
            },
          },
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
