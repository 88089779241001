import React, { useState, useMemo, memo, useEffect } from "react";
// import Button from "../../../components/sub-components/Button";
// import { ReactComponent as InfoIcon } from "../../../assets/icons/Info.svg";
import BarGraph from "../../../components/Analytics/BarGraph";
// import BpCheckbox from "../../../components/sub-components/Checkbox";
// import "animate.css";
import { secure_instance } from "../../../axios/axios-config";
import {
  generateCubeJsQuery,
  generateGraphQuery,
} from "../../../utilities/CubeUtils";
// import dayjs from "dayjs";
import { getGraphLoader } from "../../../utilities/Loaders";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstAndRemoveLastTwoLetters,
  getGraphGranularityMappings,
} from "../../../utilities/Utils";
import { triggerProductivityGraphReFetch } from "../../../redux/slices/dashboardSlice";

// import MyWorker from "../../../../public/cube-worker";

function AnalyticsCardNew(props) {
  const {
    usersData,
    // graphDataTemp,
    globalSelectedFilter,
    globalSelectedCategory,
    graphName,
    isRefreshAnalytics,
    indexBy,
    graphType,
    hideDateOnToolTip,
    isTeamSelected,
    userProfilesExtracted,
    organizationItemSelected,
  } = props;

  // const [startDate, setStartDate] = useState(
  //   dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  // );
  // const [endDate, setEndDate] = useState(
  //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  // );

  const { startDate, endDate } = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalDateRange,
  );
  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const isTriggerProductivityGraphRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerProductivityGraphRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  const dispatch = useDispatch();

  // console.log(startDate, endDate);
  // useEffect(() => {
  //   console.log("date changed");
  // }, [startDate, endDate]);

  // const [filterInputValue, setFilterInputValue] = useState("");
  const [menuItemSelected, setMenuItemSelected] = useState([]);
  const [userSelected, setUserSelected] = useState({});
  const [userApplied, setUserApplied] = useState({});
  const [selectedGranularity, setSelectedGranularity] = useState("daily");
  const [selectedGroupByItem, setSelectedGroupByItem] = useState(
    graphType === "eventTypeGraph" ? "source" : "none",
  );

  const usersAnchorEl = React.useRef(null);
  const [isUsersMenuOpen, setIsUsersMenuOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const granularityAnchorEl = React.useRef(null);
  const [isGranularityMenuOpen, setIsGranularityMenuOpen] =
    React.useState(false);

  const groupByAnchorEl = React.useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = React.useState(false);

  // const [graphData, setGraphData] = useState([]);

  const [isGraphLoading, setIsGraphLoading] = useState(false);
  // const [groupBySelected, setGroupBySelected] = useState("none");
  const [groupByGraphKeys, setGroupByGraphKeys] = useState("none");
  // const [graphUserFilters, setGraphUserFilters] = useState({
  //   model: "GroupMembers",
  //   filters: [],
  //   // group: [],
  //   group: ["group_id"],
  // });
  const [graphUserFilters, setGraphUserFilters] = useState(
    organizationGlobalFilter,
  );
  // const [graphQuery, setGraphQuery] = useState(false);

  // const filtersInitialState = [
  //   {
  //     member: "DumpTableMain.eventTime",
  //     operator: "inDateRange",
  //     values: [startDate, endDate],
  //   },
  // ];
  // const filtersInitialState = {
  //   1: {
  //     col: "event_time",
  //     operator: "between",
  //     value: [
  //       dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
  //       dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
  //     ],
  //     // value: ["2023-11-23 12:04:48", "2023-11-24 12:04:48"],
  //   },
  // };
  // const [filters, setFilters] = useState(filtersInitialState);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const rearrangeGraphDataForGroupBy = async (
    data,
    selectedGroupByItem,
    indexByKey,
  ) => {
    return new Promise((resolve, reject) => {
      const worker = new window.Worker("./cube-worker.js");
      worker.postMessage({
        message: "REARRANGE_GROUPBY_DATA",
        data: {
          data,
          selectedGroupByItem,
          indexByKey,
        },
      });
      worker.onerror = (err) => {
        console.log("~web worker error", err);
      };
      worker.onmessage = (e) => {
        const { message, result, error } = e.data;
        if (!error && message === "REARRANGE_GROUPBY_DATA_RESULT") {
          // console.log("111111111111111111111111111111111111111111111111111111111111111111", result);
          resolve({ uniqueKeys: result.uniqueKeys, groups: result.groups });
          // return { uniqueKeys: result.uniqueKeys, groups: result.groups };
        }
        worker.terminate();
      };
    });
  };

  // const getGlobalFilterDataFormat = (heading, data, uniqueID, dataToAccess) => {
  //   const searchData = data?.filter((val) => {
  //     if (filterInputValue === "") {
  //       return val;
  //     } else if (
  //       filterInputValue &&
  //       val.name.toLowerCase().includes(filterInputValue.toLowerCase())
  //     ) {
  //       return val;
  //     }
  //   });

  //   return (
  //     <>
  //       <div style={{ height: "auto" }}>
  //         {searchData.map((obj) => (
  //           <div
  //             key={obj[uniqueID]}
  //             className="d-flex justify-content-between align-items-center"
  //             // onClick={() => props.handleClickCheckbox(team)}
  //           >
  //             <MenuItem
  //               disableRipple
  //               onClick={(e) => {
  //                 handleClickMenuItem(obj, uniqueID, heading.toLowerCase());
  //                 // handleClickTempMenuItem(obj, uniqueID);
  //               }}
  //               style={{ oApplyverflow: "hidden", padding: "0 8px", margin: 0 }}
  //               className="d-flex justify-content-between align-items-center w-100"
  //             >
  //               <div style={{ display: "flex", alignItems: "center" }}>
  //                 <span
  //                   className="s2 grey8"
  //                   style={{
  //                     display: "flex",
  //                     padding: "0",
  //                     marginLeft: "0px",
  //                     cursor: "pointer",
  //                     alignItems: "center",
  //                   }}
  //                 >
  //                   <BpCheckbox
  //                     checked={isSelected(obj)}
  //                     sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
  //                     width={24}
  //                     height={24}
  //                   />
  //                   {obj[dataToAccess]}
  //                 </span>
  //               </div>

  //               <InfoIcon
  //                 style={{ width: "20px", height: "20px", cursor: "pointer" }}
  //                 stroke="#ADB5BD"
  //                 stroke-width="1.5"
  //               />
  //             </MenuItem>
  //           </div>
  //         ))}
  //       </div>
  //     </>
  //   );
  // };

  const handleClickCheckbox = (item) => {
    if (item === userSelected) {
      setUserSelected({});
    } else {
      setUserSelected(item);
    }
  };

  const handleApplyUserFilter = () => {
    handleGenericMenuClose(setIsUsersMenuOpen);
    setUserApplied(userSelected);

    // const organizationGlobalFilterClone = organizationGlobalFilter;

    // const userFilters = organizationGlobalFilterClone;

    if (Object.keys(userSelected).length === 0) {
      if (Object.keys(organizationItemSelected).length === 0) {
        // userFilters.filters = [];
        setGraphUserFilters((prevState) => ({
          ...prevState,
          filters: [],
        }));
      } else if (organizationItemSelected) {
        // userFilters.filters = [
        //   {
        //     col: "group_id",
        //     operator: "==",
        //     value: organizationItemSelected.group_id,
        //   },
        // ];
        setGraphUserFilters((prevState) => ({
          ...prevState,
          filters: [
            {
              col: "group_id",
              operator: "==",
              value: organizationItemSelected.group_id,
            },
          ],
        }));
      }
    } else if (Object.keys(userSelected).length > 0) {
      // userFilters.filters = [
      // {
      //   col: "user_id",
      //   operator: "==",
      //   value: userSelected.user_id,
      // },
      // ];
      setGraphUserFilters((prevState) => ({
        ...prevState,
        filters: [
          {
            col: "user_id",
            operator: "==",
            value: userSelected.user_id,
          },
        ],
      }));
    }

    dispatch(triggerProductivityGraphReFetch(true));
    setTimeout(() => {
      dispatch(triggerProductivityGraphReFetch(false));
    }, 1000);
  };

  const handleCancelUserFilter = () => {
    handleGenericMenuClose(setIsUsersMenuOpen);
    setUserSelected(userApplied);
  };

  const handleGraphDataFetch = async (
    query,
    groupByItem,
    selectedGranularity,
  ) => {
    setIsGraphLoading(true);
    const response = await getGraphDataWithChMaestro(query);
    console.log("groupByItemgroupByItem", groupByItem);
    const indexByForRearrangeFunction =
      getGraphGranularityMappings(selectedGranularity);

    if (!response?.graph) return;

    const { graph } = response;
    if (groupByItem === "none") {
      setGraphData(graph);
      console.log("graphgraph", graph);
      setGroupByGraphKeys([]);
    } else {
      const { uniqueKeys, groups } = await rearrangeGraphDataForGroupBy(
        graph,
        groupByItem,
        indexByForRearrangeFunction,
      );
      console.log("uniqueKeys", uniqueKeys);
      console.log(" groups groups", groups);
      setGroupByGraphKeys([...new Set(uniqueKeys)]);
      setGraphData(groups);
    }

    setIsGraphLoading(false);
  };

  const handleGroupByOptionSelected = async (e) => {
    setSelectedGroupByItem(e.target.id);
    handleGenericMenuClose(setIsGroupByMenuOpen);
    if (e.target.id === "teams") {
      const query = generateGraphQuery(
        dashboardGlobalFilters,
        graphUserFilters,
        "none",
        getGraphGranularityMappings(selectedGranularity),
      );
      handleGraphDataFetch(query, e.target.id, selectedGranularity);
      return;
    }
    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      e.target.id,
      getGraphGranularityMappings(selectedGranularity),
    );

    handleGraphDataFetch(query, e.target.id, selectedGranularity);
  };

  const handleGranularityOptionSelected = async (e) => {
    setSelectedGranularity(e.target.id);
    handleGenericMenuClose(setIsGranularityMenuOpen);

    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      selectedGroupByItem,
      getGraphGranularityMappings(e.target.id),
    );

    handleGraphDataFetch(query, selectedGroupByItem, e.target.id);
  };

  const getGraphDataWithChMaestro = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/graphs",
        method: "Post",
        data: query,
      });
      console.log("request response", request);
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  useMemo(async () => {
    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      selectedGroupByItem,
      getGraphGranularityMappings(selectedGranularity),
    );

    handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
  }, []);

  useMemo(async () => {
    if (isTriggerProductivityGraphRefetch || isTriggerRefetch) {
      const query = generateGraphQuery(
        dashboardGlobalFilters,
        graphUserFilters,
        selectedGroupByItem,
        getGraphGranularityMappings(selectedGranularity),
      );

      handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
    }
  }, [isTriggerProductivityGraphRefetch, isTriggerRefetch]);

  return (
    <div className="w-100" style={{ padding: "32px 0" }}>
      <div
        style={{
          height: "420px",
          border: "1px solid #C6C6C6",
          borderRadius: "8px",
          background: "#FFFFFF",
          boxShadow: "2px 2px 15px 0px #E5D5F2",
          // paddingBottom: "8px",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "24px 32px 0 32px",
            // borderBottom: "1px solid #E9ECEF",
            // height: "86px",
          }}
        >
          <div className="s1 d-flex align-items-center">{graphName}</div>

          <div className="d-flex align-items-center">
            <PopperDropdown
              anchorRef={groupByAnchorEl}
              isDropdownOpen={isGroupByMenuOpen}
              handleToggle={() => handleGenericMenuOpen(setIsGroupByMenuOpen)}
              handleClose={() => handleGenericMenuClose(setIsGroupByMenuOpen)}
              dropdownDisplayText={
                selectedGroupByItem === "none"
                  ? "Group By"
                  : selectedGroupByItem
              }
              menuItems={["None", "Users", "Event Types", "Sources", "Teams"]}
              handleOptionSelected={handleGroupByOptionSelected}
              activeMenuItem={selectedGroupByItem}
            />

            <div className="mr-3" />

            <PopperDropdown
              anchorRef={granularityAnchorEl}
              isDropdownOpen={isGranularityMenuOpen}
              handleToggle={() =>
                handleGenericMenuOpen(setIsGranularityMenuOpen)
              }
              handleClose={() =>
                handleGenericMenuClose(setIsGranularityMenuOpen)
              }
              dropdownDisplayText={capitalizeFirstAndRemoveLastTwoLetters(
                selectedGranularity === "daily" ? "Dayly" : selectedGranularity,
              )}
              menuItems={["Hourly", "Daily", "Weekly", "Monthly", "Yearly"]}
              handleOptionSelected={handleGranularityOptionSelected}
              activeMenuItem={selectedGranularity}
            />
            {isTeamSelected && (
              <>
                <div className="mr-3" />

                <PopperDropdown
                  anchorRef={usersAnchorEl}
                  isDropdownOpen={isUsersMenuOpen}
                  handleToggle={() => handleGenericMenuOpen(setIsUsersMenuOpen)}
                  handleClose={() => handleCancelUserFilter()}
                  dropdownDisplayText="Users"
                  dropdownWithSearchAndCheckbox
                  dropdownData={userProfilesExtracted}
                  handleClickCheckbox={handleClickCheckbox}
                  handleApply={handleApplyUserFilter}
                  handleCancel={handleCancelUserFilter}
                  dropdownItemsSelected={userSelected}
                />
              </>
            )}
          </div>
        </div>

        {isGraphLoading ? (
          <div
            style={{
              minWidth: "95%",
              paddingLeft: "30px",
              height: "398px",
              marginTop: "34px",
              maxHeight: "fit-content",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              {getGraphLoader()}
              <div style={{ position: "absolute", left: "43%", top: "25%" }}>
                {/* <FullPageLoading height="50%" /> */}
                <div className="h5 grey9 mt-3">Loading Data</div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              minWidth: "95%",
              maxWidth: "99%",
              paddingLeft: "30px",
              marginTop: "10px",
            }}
          >
            <BarGraph
              // startDate={startDate}
              // endDate={endDate}
              // dateChanged={dateChanged}
              // setDateChanged={setDateChanged}
              selectedGranularity={selectedGranularity}
              // groupByGraphKeys={groupByGraphKeys}
              usersData={usersData}
              graphContainerHeight={"380px"}
              selectedGroupByItem={selectedGroupByItem}
              graphData={graphData}
              // graphData={ffff}
              currentFilters={[]}
              memberSelected={menuItemSelected}
              globalSelectedCategory={globalSelectedCategory}
              isRefreshAnalytics={isRefreshAnalytics}
              globalSelectedFilter={globalSelectedFilter}
              graphName={graphName}
              indexBy={getGraphGranularityMappings(selectedGranularity)}
              groupByGraphKeys={groupByGraphKeys}
              // tooltip={null}
              // tooltip={(input) => {
              //   return input;
              // }}
              hideDateOnToolTip={hideDateOnToolTip}
              // Top10PerformersGraph={Top10PerformersGraph}
              // matchingUsersInGraphData={matchingUsersInGraphData}

              padding={0.09}
              // colors={"#A790FF"}
              // isAxisBottomDisplay={false}
              // isAxisLeftDisplay={false}
              // enableGridX={false}
              // enableGridY={false}
              borderRadius={10}
              useCanvas={true}
              // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            />
          </div>
        )}
      </div>
      {/* </div > */}
    </div>
  );
}

export default memo(AnalyticsCardNew);
