import React, { useState } from "react";
import { ReactComponent as DotsThreeHorizontal } from "../../../../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../../../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../../../../assets/icons/PencilSimple.svg";
import { StyledMenu } from "../../../../../components/Menus/Menu";
import { MenuItem } from "@mui/material";
import { setSideMenuTablePage } from "../../../../../redux/slices/departmentSlice";
import { useDispatch } from "react-redux";
import Button from "../../../../../components/sub-components/Button";

const ActionButtons = ({
  teamName,
  selectedHOD,
  addedTeams,
  handleSideMenuClose,
  loading,
  isRowClicked,
  isEditableState,
  deleteDepartment,
  setIsEditableState,
  isConfirmationModal,
  setIsConfirmationModal,
  handleCreateNewDepartement,
  setSelectedTab,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isButtonActionMenu = Boolean(anchorEl);

  function isNextButtonActive(teamName, selectedHOD, addedTeams) {
    return teamName && selectedHOD && addedTeams.length > 0;
  }

  const nextActive = isNextButtonActive(teamName, selectedHOD, addedTeams);
  const nextStyle = {
    backgroundColor: nextActive ? "#9300FF" : "#7B2CBF66",
    cursor: nextActive ? "pointer" : "no-drop",
  };

  const dispatch = useDispatch();

  const handleThreeDotsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  if (isRowClicked && !isEditableState) {
    return (
      <>
        <div
          style={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
          }}
        >
          <DotsThreeHorizontal onClick={handleThreeDotsOpen} />
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={isButtonActionMenu}
            onClose={handleThreeDotsClose}
          >
            <MenuItem
              disableRipple
              onClick={() => {
                deleteDepartment();
              }}
            >
              <Fi_trash
                stroke="#343A40"
                style={{ width: "16px", height: "16px" }}
              />
              <span
                className="s2 grey8 d-flex align-items-center"
                style={{ marginLeft: "8px" }}
              >
                Delete
              </span>
            </MenuItem>
          </StyledMenu>
        </div>
        <div style={{ marginRight: "16px" }}>
          <PencilSimple
            style={{
              width: "32px",
              height: "32px",
              cursor: "pointer",
            }}
            onClick={() => setIsEditableState(true)}
          />
        </div>
      </>
    );
  }

  if (!isConfirmationModal) {
    return (
      <>
        <Button
          buttonFilledLg
          typography="body1-medium grey0"
          title="Next"
          loading={loading}
          style={nextStyle}
          onClick={() => {
            setIsConfirmationModal(true);
            dispatch(setSideMenuTablePage(1));
            setSelectedTab({ id: 1 });
          }}
          disabled={!nextActive}
        />
        <Button
          buttonHollowLg
          typography="s1 grey6"
          title="Cancel"
          onClick={handleSideMenuClose}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          buttonFilledLg
          typography="body1-medium grey0"
          title="Confirm"
          loading={loading}
          style={nextStyle}
          onClick={handleCreateNewDepartement}
          disabled={!nextActive}
        />
        <Button
          buttonHollowLg
          typography="s1 grey6"
          title="Cancel"
          onClick={handleSideMenuClose}
        />
      </>
    );
  }
};

export default ActionButtons;
